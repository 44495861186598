import * as getAllCarModelAction from "../../slices/master/carModel.slice";
import { http } from "../../../utils/http";
import { apiUrl, endPoint } from "../../../constants/server/api";

export const getAllCarModel = (/* year,car_brand_id */{ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(getAllCarModelAction.fetchData());

    try {
      let url = endPoint.CAR_MODEL;
                  
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
      };
      /* const config = {
        method: "get",
        url: `${apiUrl}/car-model/list`,
        params: {
          year,
          car_brand_id
        },
      }; */

      const result = await http(config);
      dispatch(getAllCarModelAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllCarModelAction.fetchDataFailure());
    }
  };
};
