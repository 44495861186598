import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/text.css';
import './styles/index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store/configureStore'; // นำเข้า store ของคุณ

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GlobalLoaderProvider } from "contexts/GlobalLoaderContext";
import { GlobalModalProvider } from "contexts/GlobalModalContext";
import { GlobalSelectedBranchProvider } from "contexts/GlobalSelectedBranchContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
/* root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
); */
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="224450368848-n18vttkv84nf51a7hknmd22ph48s5mjq.apps.googleusercontent.com">
        <GlobalLoaderProvider>
          <GlobalModalProvider>
            <GlobalSelectedBranchProvider>
              <App />
            </GlobalSelectedBranchProvider>
          </GlobalModalProvider>
        </GlobalLoaderProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
