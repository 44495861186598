import * as getAllCarSubModelAction from "../../slices/master/carSubModel.slice";
import { http } from "../../../utils/http";
import { apiUrl, endPoint } from "../../../constants/server/api";

export const getAllCarSubModel = ({ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(getAllCarSubModelAction.fetchData());

    try {
      let url = endPoint.CAR_SUB_MODEL;
            
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
      };
      /* const config = {
        method: "get",
        url: `${apiUrl}/car-sub-model/list`,
        params: {
          car_model_id
        },
      }; */

      const result = await http(config);
      dispatch(getAllCarSubModelAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllCarSubModelAction.fetchDataFailure());
    }
  };
};
