import React, { createContext, useContext, useState } from 'react';

// Create a context with a default undefined value
const GlobalSelectedBranchContext = createContext(undefined);

// This is your provider component
export const GlobalSelectedBranchProvider = ({ children }) => {
  const [selectedBranchId, setSelectedBranchId] = useState(3);

  const contextValue = {
    selectedBranchId,
    setSelectedBranchId,
  };

  return (
    <GlobalSelectedBranchContext.Provider value={contextValue}>
      {children}
    </GlobalSelectedBranchContext.Provider>
  );
};

export const useGlobalSelectedBranchContext = () => {
  const context = useContext(GlobalSelectedBranchContext);
  if (!context) {
    throw new Error('useGlobalSelectedBranchContext must be used within a GlobalSelectedBranchProvider');
  }
  return context;
};
