
import Form from 'react-bootstrap/Form';
import React, { useState,useEffect } from 'react';
import {useNavigate,useLocation,useParams} from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useAppDispatch ,useAppSelector} from "../../../redux/store/configureStore";
import Select from 'react-select';
import Text from 'fragments/Text';
import {CurrencyFormatText} from 'fragments/Currency';
import {RepairType,FirstPartDamages,InsuranceFund,AdditionalServices,InsuranceCompany} from 'layouts/filters';
import {InsuranceCompare} from 'layouts/Compare';
import { getCarInsuranceById } from "../../../redux/handlers/master/carInsurance.handler";
import { getAllPlace } from "../../../redux/handlers/master/place.handler";
import { getPaginationInsuranceCompany } from "../../../redux/handlers/master/insuranceCompany.handler";
import { getAllInsuranceAddonService } from "../../../redux/handlers/master/insuranceAddonService.handler";


const AdditionalServicesBox = ({icon,message,service}) => {


    return (
        <>
            <div 
                className='d-flex align-items-center flex-column justify-content-center'
                style={{
                boxShadow:' 0px 1px 6px 0px #D8E7FD80',
                height:'134px',
                background:'#FFFAE8',
                ...( !service ? {filter:'grayscale(100%)'} : {} )
                }}
            >
                <Image src={icon}></Image>
                <div className='mt-2'>
                    <Text c='#2F2F2F' s="15px" >{message}</Text>
                </div>
            </div>
        </>
    )
}

const IconCustomCheck = ({status}) => {
    return (
        <>
            <span>
                {
                    status ? 
                    <Image src='/images/icon/check.png' className='me-2' style={{marginTop:'-3px'}}></Image>  
                    :
                    <Image src='/images/icon/cross.png' className='me-2' style={{marginTop:'-3px'}}></Image>
                }
                
            </span>
        </>
    )
}

const InsuranceCompanyList = ({data,handleCompareSelect,compare,marketingDisplay,services}) => {

    const checkcompare = compare.filter(item => item.id == data?.id);
    let defaultTheme = {
        "bg_body":'#FFF',
        "t1":'#8955C1',
        "t2":'#2F2F2F',
        "t3":'#8955C1',
        "bg_price":"#FFA3190D",
        "bg_center":"linear-gradient(90deg, #B2B2B2 0%, #EAEAEA 51.45%, #BBBBBB 100%)",
        "bg_footer":'#8955C10D'
    };

    const [templatecolor, setTextcolor] = useState(defaultTheme);

    useEffect(() => {

        if(data && data?.is_popular_package){
            setTextcolor({
                "bg_body":'#8955C1',
                "t1":'#FFA319',
                "t2":'#FFF',
                "t3":'#FFF',
                "bg_price":"#00000040",
                "bg_center":'linear-gradient(90deg, #F3C522 0%, #FFF382 38.9%, #FFF68A 68.02%, #E1B518 95.9%)',
                "bg_footer":'#3A2451'
            })
        }else{
            setTextcolor(defaultTheme)
        }


    }, [data]);

    const [isCollapse, setCollapse] = useState(false)
    const toggle = () => setCollapse(!isCollapse);

    return (
        <>

            <div className="card border-0 mb-4" style={
                {
                    boxShadow: '0px 1px 6px 0px #3A97E233',
                }
            }>
                { data && data?.is_popular_package ? (
                <div className='card-header position-relative pt-1'
                style={{background: '#FFBF00',height:'45px'}}
                >
                    <Image src='/images/icon/like_icon.png' style={{marginTop:'-10px'}} />

                    <Text c='#5F4A00' w={600} s='20px' lh={1} className='ms-2'>แพ็คเกจยอดนิยม</Text>
                </div>
                ) : '' }

                <div className='card-body px-xl-4'
                    style={{...(1 ? {backgroundColor:templatecolor.bg_body} : {} )}}
                >
                    <div className='d-flex  '>
                        <div>
                            <div className='d-flex align-items-start mb-3'>
                                <Image src={data?.insurance_image} style={{width:'60px',height:'60',objectFit:'cover'}} />
                                <div className='ms-3'>
                                    <div className='mb-2'> <Text c={templatecolor.t1} s="17px" w={600} > {data?.insurance_name} </Text> </div>
                                    <div className='d-flex align-items-center text-nowrap'>
                                        <div className='me-3'> <Text c={templatecolor.t2} s="17px"  w={600} lh="1" >{data?.insurance_type}  </Text>  </div>
                                        <div className='repaircenter1'
                                        style={{background: templatecolor.bg_center }}
                                        >{data?.repair_type}</div>
                                    </div>
                                </div>
                            </div>
                            <div > 
                                <IconCustomCheck status={data?.is_named_driver} />
                                <Text c={templatecolor.t2} s="15px"  > ไม่ระบุชื่อผู้ขับขี่ </Text>
                            </div>
                        </div>
                        <div className='ps-5 flex-fill d-flex align-items-end justify-content-around'>
                            <div style={{maxWidth:"350px"}}>
                                <div className='mb-2'> <Text c={templatecolor.t2} s="17px" w={600} > บริการเสริม </Text> </div>
                                <div className='row'>
                                {
                                   
                                services && services.map((item, index) => {
                                    // ตรวจสอบว่ามีข้อมูลใน data?.insurance_addon_service ที่ตรงกับ item.id หรือ item.name หรือไม่
                                    const isMatched = data?.insurance_addon_service.some(addon => 
                                    addon.id === item.id || addon.name === item.name
                                    );
                                    
                                    return (
                                    <div className='col-6' key={index}>
                                        {/* ส่งค่า true หรือ false ไปยัง IconCustomCheck */}
                                        <IconCustomCheck status={isMatched} />
                                        <Text c={templatecolor.t2} s="15px">{item.name}</Text>
                                    </div>
                                    );
                                })
                                }

                                   {/*  <div className='col-6'>
                                        <IconCustomCheck status={data?.insurance_addon_service.emergency_help} />
                                        <Text c={templatecolor.t2} s="15px"  > ช่วยเหลือฉุกเฉิน </Text>
                                    </div>
                                    <div className='col-6'>
                                        <IconCustomCheck status={data?.insurance_addon_service.natural_disaster} />
                                        <Text c={templatecolor.t2} s="15px"  > ภัยธรรมชาติ </Text>
                                    </div>
                                    <div className='col-6'>
                                        <IconCustomCheck status={data?.insurance_addon_service.flood_danger} />
                                        <Text c={templatecolor.t2} s="15px"  > ภัยน้ำท่วม </Text>
                                    </div>
                                    <div className='col-6'>
                                        <IconCustomCheck status={data?.insurance_addon_service.car_used_during_repair} />
                                        <Text c={templatecolor.t2} s="15px"  > รถใช้ระหว่างซ่อม </Text>
                                    </div> */}
                                </div>
                            </div>
                            <div
                                className='d-flex flex-column align-items-end h-100'
                            >
                                <div className='mb-auto'>
                                    <Form.Check
                                        type="checkbox"
                                        label="เปรียบเทียบ"
                                        id={`compair${data?.id}`}
                                        onChange={(e) => {
                                            handleCompareSelect(e.target.checked,data)
                                        }}
                                        style={{
                                            color: templatecolor.t3,
                                            lineHeight:1.8
                                        }}
                                        checked={checkcompare.length > 0}
                                    />
                                </div>

                                <div
                                    style={{
                                        background: templatecolor.bg_price,
                                        borderRadius:'8px',
                                        padding:'10px 20px',
                                        whiteSpace:'nowrap'
                                    }}
                                >   
                                    <Text c="#FFA319" s="28px" w={600}  > 
                                        <CurrencyFormatText value={data?.price} />
                                    </Text>
                                    <Text c={templatecolor.t2} s="14px"  className='ms-1' > บาท/ปี </Text>
                                </div>
                            </div>
                        </div>

                    </div>

                    
                </div>
                <div className='bg-white '>
                <Collapse in={isCollapse}>
                    <div className='px-4' >
                    <div className='my-4' style={{height:'3px',background:'#8955C1'}}></div>
                    <div className='row gx-xl-4'>
                        <div className='col-6'>
                            <Text c='#2F2F2F' s="17px" w={600}>กรณีเป็นฝ่ายผิด / ประมาทร่วม / ไม่มีคู่กรณี</Text>

                            <div className='row my-4'>
                                <div className='col-6'>
                                    <Text c='#2F2F2F' s="14px" w={300}>ค่าเสียหายส่วนแรก</Text>
                                </div>
                                <div className='col-6'>
                                    <Text c='#2F2F2F' s="15px" w={600}>{data?.is_excess?data?.excess:"ไม่มี"}</Text>
                                </div>
                            </div>
                             <div className='mb-3'>
                                <Text c='#2F2F2F' s="17px" w={600}>บริการเสริม</Text>
                            </div>
                            <div className='row mb-4 g-4'>
                            {
                                services && services.map((item, index) => {
                                    // ตรวจสอบว่ามีข้อมูลใน data?.insurance_addon_service ที่ตรงกับ item.id หรือ item.name หรือไม่
                                    const isMatched = data?.insurance_addon_service.some(addon => 
                                    addon.id === item.id || addon.name === item.name
                                    );
                                    let icon 
                                    if(item.name == 'ช่วยเหลือฉุกเฉิน'){
                                        icon='/images/icon/clock.png'
                                    } else if(item.name == 'ภัยธรรมชาติ'){
                                        icon='/images/icon/car.png'
                                    } else if(item.name == 'ภัยน้ำท่วม'){
                                       icon='/images/icon/car_flood.png'
                                    } else if(item.name == 'รถใช้ระหว่างซ่อม'){
                                        icon='/images/icon/car.png'
                                     }
                                    
                                    return (
                                    <div className='col-6' key={index}>
                                        <AdditionalServicesBox
                                        icon={icon}
                                        message={item.name}
                                        service={isMatched}
                                    />

                                    </div>
                                    );
                                })
                                }
                            </div> 

                            
                        </div>
                        <div className='col-6'>
                            <div className='mb-2'><Text c='#2F2F2F' s="17px" w={600}>รถยนต์เสียหาย สูญหาย ไฟไหม้</Text></div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ความเสียหายต่อรถยนต์</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.vehicle_damage} />
                                    </Text>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>รถยนต์สูญหาย/ไฟไหม้</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.vehicle_loss_or_fire} />
                                    </Text>
                                </div>
                            </div>
                            <hr style={{opacity:0.08}} />

                            <div className='mb-2'><Text c='#2F2F2F' s="17px" w={600}>ความรับผิดต่อบุคคลภายนอก</Text></div>

                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ชีวิตบุคคลภายนอกต่อคน </Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.external_person_life_per_person} />
                                    </Text>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ชีวิตบุคคลภายนอกต่อครั้ง </Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.external_person_life_per_case} />
                                    </Text>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ทรัพย์สินบุคคลภายนอก</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.external_person_property} />
                                    </Text>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ค่าเสียหายส่วนแรก <br/> กรณีทรัพย์สินเสียหาย</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.excess_property_damage} />
                                    </Text>
                                </div>
                            </div>
                            <hr style={{opacity:0.08}} />

                            <div className='mb-2'><Text c='#2F2F2F' s="17px" w={600}>ความคุ้มครองตามเอกสารแนบท้าย</Text></div>

                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>อุบัติเหตุส่วนบุคคล</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.personal_accident} />
                                    </Text>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ค่ารักษาพยาบาลต่อคน</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.medical_expense_per_person} />
                                    </Text>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-auto'>
                                    <Text c='#1D1D1D' s="15px" w={300}>ประกันตัวผู้ขับขี่</Text>
                                </div>
                                <div>
                                    <Text c='#000000' s="15px" w={600}>
                                        <CurrencyFormatText value={data?.driver_bail} />
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </Collapse>
                </div>

                <div className='card-footer px-xl-4 d-flex align-items-center' 
                style={{borderTop:0,background: templatecolor.bg_footer }}
                >
                    {
                        marketingDisplay ? ( 
                            <div className='me-5'>
                                <div><Text c={templatecolor.t2} s="14px" lh="1" > ค่าการตลาด </Text></div>
                                <div>
                                    <Text c={templatecolor.t1} s="20px">฿</Text>
                                    <Text c={templatecolor.t1} s="20px" w={600}> <CurrencyFormatText value={data?.marketing_cost} /> </Text>
                                </div>
                            </div>
                        ) : ''
                    }
                    
                    <div className='me-5'>
                        <div><Text c={templatecolor.t2} s="14px" lh="1"> ทุนประกัน </Text></div>
                        <div>
                            <Text c={templatecolor.t1} s="20px">฿</Text>
                            <Text c={templatecolor.t1} s="20px" w={600}> <CurrencyFormatText value={data?.current_insurance_value} /> </Text>
                        </div>
                    </div>
                    <div className='me-auto'>
                        <div><Text c={templatecolor.t2} s="14px" lh="1"> ค่าเสียหายส่วนแรก </Text></div>
                        <div>
                            {
                                !isNaN(data?.excess)  ? <Text c={templatecolor.t1} s="20px">฿</Text> : ''
                            }
                            
                            <Text c={templatecolor.t1} s="20px" w={600}> <CurrencyFormatText value={data?.excess} /> </Text>
                        </div>
                    </div>
                    <div>
                        <button className='btn btn-st-1 me-3'
                            style={{background:'#8955C1',borderColor:'#8955C1'}}
                            onClick={toggle}
                        >
                            <span className='icon-list me-2'></span>
                            {isCollapse? "ย่อ" : "ดูรายละเอียด" }  
                        </button>
                    </div>
                    <div>
                        
                        <button className='btn btn-st-1'>
                            ซื้อเลย
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}


export function  InsuranceList() {
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const car_insurance_id = searchParams.get('car_insurance_id');

    const [formdata, setFormdata] = useState(null);
    const [postsToShow, setPostsToShow] = useState([]);

    const [InsuranceList, setInsuranceList] = useState([]);
    const [compare, setCompare] = useState([]);
    const [marketingDisplay, setMarketingDisplay] = useState(true);
    const [page , setPage] = useState(1)
    const [perPage ] = useState(5)
    const [total , setTotal ] = useState(0)

    const [services, setServices] = useState(false);
    const [FilterType, setFilterType] = useState([]);
    const [FilterFirstDamages, setFilterFirstDamages] = useState([]);
    const [FilterFund, setFilterFund] = useState({
        min:'',
        max:''
    });
    const [FilterService, setFilterService] = useState([]);
    const [FilterCompany, setFilterCompany] = useState([]);
    const [isFetching, setIsFetching] = useState(false); 

    useEffect(() => {
        dispatch(getAllInsuranceAddonService());
    }, []);

    const insuranceAddonServiceReducer = useAppSelector(
        (state) => state.insuranceAddonServiceReducer
      );

    useEffect(() => {
        if (insuranceAddonServiceReducer.data) {
            setServices(insuranceAddonServiceReducer.data?.data);
        }
    }, [insuranceAddonServiceReducer]);
    

    useEffect(() => {
        if(location?.state?.compare){
            setCompare(location.state.compare)
        }
    }, [location]);

    useEffect(()=> {
        dispatch(getCarInsuranceById(car_insurance_id));
    },[car_insurance_id])


    const carInsuranceReducer = useAppSelector(
        (state) => state.carInsuranceReducer
      );

      useEffect(() => {
        if (carInsuranceReducer.data) {
            setFormdata(carInsuranceReducer.data?.data);
        }
      }, [carInsuranceReducer]);

    const insuranceCompanyReducer = useAppSelector(
        (state) => state.insuranceCompanyReducer
      );

      useEffect(() => {
        if (insuranceCompanyReducer.data && Array.isArray(insuranceCompanyReducer.data.data.data)) {
          if (page === 1) {
            setInsuranceList(insuranceCompanyReducer.data.data.data);
          } else {
            setInsuranceList((prevList) => [...prevList, ...insuranceCompanyReducer.data.data.data]);
          }
          setNext(insuranceCompanyReducer.data.data.meta.next)
          setTotal(insuranceCompanyReducer.data.data.meta.total)
          setIsFetching(false); // ตั้งสถานะเป็น false หลังจากดึงข้อมูลเสร็จ
        }
      }, [insuranceCompanyReducer.data]);
    
    
    const getCompany = () => { 
        if (!isFetching) {
        setIsFetching(true);
        dispatch(getPaginationInsuranceCompany(page, perPage));
      }
    };

    useEffect(() => {
        getCompany();
    }, [page]);
    
    useEffect(() => {
        dispatch(getAllPlace());
    }, []);

    const placeReducer = useAppSelector(
        (state) => state.placeReducer
      );

      useEffect(() => {
        if (placeReducer.data) {
           /*  setFilterType(placeReducer.data?.data); */
        }
      }, [placeReducer]);

    useEffect(() => {
        setPostsToShow(InsuranceList);
    }, [InsuranceList]);
    

    const handleCompareSelect = (checked,insurance) => {
        if(checked){
            if(compare.length  < 4){
                setCompare((prev) => [...prev,insurance])
            }
            
        }else{
            setCompare(prev => prev.filter(item => item.id !== insurance.id))
        }
    }


    const handleFilter = (value,nameFilter) => {
        let marketingDisplay
        if (nameFilter == "marketingDisplay") {
            marketingDisplay = value
        }

        let sorting
        if (nameFilter == "sorting") {
            sorting = value
        }

        if (InsuranceList !== undefined && InsuranceList.length > 0) {
        let filterPlace 
      
        if (FilterType && FilterType.length>0) {
            filterPlace = FilterType.map(item => item.id).join(',');
        }
        
        let filterInsurance 

        if (FilterCompany && FilterCompany.length>0) {
            filterInsurance = FilterCompany.map(item => item.id).join(',');
        }

        let filterFirstDamages
        if(FilterFirstDamages.length > 0){
            filterFirstDamages = FilterFirstDamages.map(item => item.id).join(',');
        } 
        let filterInsuranceAddonService
        if(FilterService.length > 0){
            filterInsuranceAddonService = FilterService.map(item => item.id).join(',');
        } 

       let min_current_insurance_value 
        if(FilterFund.min > 0  ){
        
            min_current_insurance_value = FilterFund.min
        }

        let max_current_insurance_value 
        if(FilterFund.max > 0  ){
            max_current_insurance_value = FilterFund.max
        }
        
        window.scrollTo(0, 0)

        dispatch(getPaginationInsuranceCompany(page,perPage,filterPlace,filterInsurance,filterFirstDamages,filterInsuranceAddonService,min_current_insurance_value,max_current_insurance_value,marketingDisplay,sorting))

    }
    }

   


    const handleResetFilter = () => {
        setPostsToShow(InsuranceList);
        setFilterType(placeReducer.data?.data)
        setFilterFirstDamages([])
        setFilterFund({
            min:'',
            max:''
        })
        setFilterService([])
        setFilterCompany([])
    }


    const [sort, setSort] = useState(null);
    const optionSort = [
            {
                "value":"current_insurance_value",
                "label" : "ทุนประกันจากน้อยไปมาก"
            },
            {
                "value":"-current_insurance_value",
                "label" : "ทุนประกันจากมากไปน้อย"
            },
       /*  { value: 1, label: 'แนะนำ' },
        { value: 2, label: 'ทุนประกันจากน้อยไปมาก' },
        { value: 3, label: 'ทุนประกันจากมากไปน้อย' },
        { value: 4, label: 'ค่าเบี้ยจากน้อยไปมาก' },
        { value: 5, label: 'ค่าเบี้ยจากมากไปน้อย' },
        { value: 6, label: 'ค่าการตลาดน้อยไปมาก' },
        { value: 7, label: 'ค่าการตลาดจากมากไปน้อย' }, */
    ]



    const [next, setNext] = useState(1);
    const handleShowMorePosts = () => {
        setPage((prevPage) => prevPage + 1);
      };
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='pb-5' >
            <div className='row'>
                <div className='col-auto'>
                    <div className='card card-st-3' style={{width:"320px",border:0,backgroundColor:'rgba(137, 85, 193, 0.05)'}}>

                        <div className='card-body'>
                            <div className="mb-3">
                                <Text c="#1D1D1D" s="20px" > ตัวกรอง</Text>
                            </div>
                            
                            <RepairType objFilterType={{FilterType,setFilterType}}  />
                            <hr style={{opacity:0.08}} />
                            <FirstPartDamages objFilter={{FilterFirstDamages,setFilterFirstDamages}} />
                            <hr style={{opacity:0.08}} />
                            <InsuranceFund objFilter={{FilterFund,setFilterFund}} />
                            <hr style={{opacity:0.08}} />
                            <AdditionalServices objFilterService={{FilterService,setFilterService}} />
                            <hr style={{opacity:0.08}} />
                            <InsuranceCompany  objFilterCompany={{FilterCompany,setFilterCompany}}  />
                            <div className='text-start py-4'>
                                <Button 
                                className='btn btn-st-1 fs-16 me-4'
                                style={{width:'120px'}}
                                onClick={() => handleFilter() }
                                >ค้นหา</Button>

                                <Button 
                                className='btn btn-link fs-16'
                                style={{color:'#8955C1'}}
                                onClick={() => handleResetFilter() }
                                >ล้างค่า</Button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col'>


                    <div className='card mb-4' style={{border:0,boxShadow: '0px 4px 4px 0px #4C4E6440'}}>
                        <div className='card-body d-flex align-items-center'>
                            <Image src="/images/image-2.png" />
                            <div className='ms-3 flex-fill'>
                                <div className='mb-2'>
                                    <Text s='14px' c='#6D6D6D'>ข้อมูลรถของคุณ</Text>
                                </div>

                                <div>
                                    <Text s='18px' c='#000A1B'>
                                        {
                                            formdata ? formdata.insuranceType.name : ''
                                        }
                                    </Text>
                                    <Text s='18px' c='#000A1B'> / </Text>
                                    {/* <Text s='18px' c='#000A1B'> ซ่อมอู่ </Text>
                                    <Text s='18px' c='#000A1B'> / </Text> */}
                                    <Text s='18px' c='#000A1B'  className='text-uppercase'>
                                        {
                                            formdata ? formdata.carBrand.name : ''
                                        }
                                    </Text>
                                    <Text s='18px' c='#000A1B'> / </Text>
                                    <Text s='18px' c='#000A1B' className='text-uppercase'>
                                        {
                                            formdata ? formdata.carModel.name : ''
                                        }
                                    </Text>
                                    <Text s='18px' c='#000A1B'> / </Text>
                                    <Text s='18px' c='#000A1B'>
                                        {
                                            formdata ? <CurrencyFormatText value={formdata.current_insurance_value} />  : ''
                                        }
                                    </Text>
                                </div>

                                
                            </div> 
                            <div className=''>
                                <button className='btn btn-edit-st-1'
                                onClick={() => {
                                    navigate(`/insurance/premium?car_insurance_id=${car_insurance_id}`);
                                }}
                                >แก้ไข</button>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex align-items-center mb-4'>
                        <div className='me-auto'>
                            <div className='mb-2 '><Text s='16px' c='#000000D9'>พบ { postsToShow ? postsToShow.length : ''} รายการ</Text></div>
                            <div><Text s='14px' c='#8955C1'>เลือกเปรียบเทียบสูงสุด 4 รายการ</Text> </div>
                        </div>
        
                        <div className='me-3' style={{color:'rgba(76, 78, 100, 0.87)',lineHeight:1.6}}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="แสดงค่าการตลาด"
                                className='custom-switch'
                                onChange={(e) => {
                                    setMarketingDisplay(e.target.checked)
                                    handleFilter(e.target.checked,"marketingDisplay")
                                   
                                }}
                                checked={marketingDisplay}
                            />
                        </div>
                        <div>
                            <Select
                                as="select"
                                name="year"
                                onChange={(e) => {
                                    setSort(e.value)
                                    handleFilter(e.value,"sorting")
                                   
                                }}
                                className="w-100 select-st-2 border-0 btn-sort-st-1 text-start pe-0"
                                options={optionSort}
                                placeholder="เรียงจาก"
                                value={optionSort.filter((i) =>  sort == i.value)}
                            />

                            {/* <button className='btn btn-sort-st-1'>เรียงจาก</button> */}
                        </div>
                    </div>
                    {postsToShow &&
                        postsToShow.map((item, index) => (
                        <div key={index}>
                            <InsuranceCompanyList
                            data={item}
                            compare={compare}
                            handleCompareSelect={(checked, insurance) =>
                                handleCompareSelect(checked, insurance)
                            }
                            marketingDisplay={marketingDisplay}
                            services={services}
                            />
                        </div>
                        ))}
                    

                    <div className='py-3 my-3 text-center'>
                        {
                            next ? (
                                <Button className='btn-st-3'
                                onClick={handleShowMorePosts}
                                >ดูแผนประกันเพิ่มเติม</Button>
                            ) : ''
                        }
                        
                    </div>


                    <div className='py-5' ></div>
                </div> 
                
            </div>
            { 
                compare && compare.length > 0 ? (
                    <InsuranceCompare formdata={formdata} compare={compare} setCompare={setCompare} />
                ) : ''
            
            }
            
        </div>
    )
}