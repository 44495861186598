import * as getAllColorActions from "../../slices/master/color.slice";
import { http } from "../../../utils/http";
import { endPoint } from "constants/server/api";

export const getAllColor = ({ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(getAllColorActions.fetchData());

    try {
      let url = endPoint.COLOR;
      
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
      };

      const result = await http(config);
      dispatch(getAllColorActions.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllColorActions.fetchDataFailure());
    }
  };
};
