import * as getAllLicensePlateProvinceAction from "../../slices/master/licensePlateProvince.slice";
import { http } from "../../../utils/http";
import { apiUrl, endPoint } from "../../../constants/server/api";

export const getAllLicensePlateProvince = ({ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(getAllLicensePlateProvinceAction.fetchData());

    try {
      let url = endPoint.LICENSE_PLATE_PROVINCE;
      
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
      };

      const result = await http(config);
      dispatch(getAllLicensePlateProvinceAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllLicensePlateProvinceAction.fetchDataFailure());
    }
  };
};
