import React, { useState,useEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Image from 'react-bootstrap/Image';
import DeleteIcon from 'assets/icon/solar_trash-bin-trash-outline.svg'
import EditIcon from 'assets/icon/cil_pencil.svg'

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import zIndex from '@mui/material/styles/zIndex';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SelectS1 } from 'fragments/Select';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import { ButtonM1,ButtonM2,ButtonM3 } from 'fragments/ButtonModal';


import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { useGlobalLoaderContext } from 'contexts/GlobalLoaderContext';
import { getAllCarSubModel } from '../../redux/handlers/master/carSubModel.handler';
import { getCarModelList } from '../../redux/handlers/master/getCarModelList.handler';
import { mapCarBrandOptions, mapCarModelOptions } from 'utils/HelperFunction';
import { getCarBrandList } from '../../redux/handlers/master/getCarBrandList.handler';
import { createCarSubModel } from '../../redux/handlers/master/createCarSubModel.handler';
import { cardActionAreaClasses, debounce } from '@mui/material';
import { updateCarSubModel } from '../../redux/handlers/master/updateCarSubModel.handler';
import { deleteCarSubModel } from '../../redux/handlers/master/deleteCarSubModel.handler';
import { useMemo } from 'react';
export function CarSubModel() {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();
    
    const [carSubModel, setCarSubModel] = useState([])

    const carSubModelReducer = useAppSelector(
        (state) => state.carSubModelReducer
    );

    const [pageModel,setPageModel] = useState({
            page: 1,
            perPage: 10,
            total: 0
        });
    
    const loadAll = (page,perPage,searchText) => {
        const queryParams = {
            page: page,
            perPage: perPage,
            searchText: searchText,
        } 
        dispatch(getAllCarSubModel({ queryParams, modalObj, setModalObj }));
    }
    

    useEffect(() => {
        loadAll(1,pageModel.perPage,filterText || "");
    },[]);

    useEffect(() => {
            setIsLoading(
                carSubModelReducer.isFetching
            )
    
            if(carSubModelReducer.data && carSubModelReducer.data.data){
                const meta = carSubModelReducer.data.data.meta;
                setPageModel({
                    page: meta.currentPage,
                    perPage: meta.perPage,
                    total: meta.total
                });
                setCarSubModel(carSubModelReducer.data.data.data); 
            }
    },[
        carSubModelReducer
    ]);

    const createCarSubModelReducer = useAppSelector(
            (state) => state.createCarSubModelReducer
    );

    useEffect(() => {
        setIsLoading(createCarSubModelReducer.isFetching);
        if(createCarSubModelReducer.data && !createCarSubModelReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    }, [createCarSubModelReducer]);

    const updateCarSubModelReducer = useAppSelector(
        (state) => state.updateCarSubModelReducer
    );

    useEffect(() => {
        setIsLoading(updateCarSubModelReducer.isFetching);
        if(updateCarSubModelReducer.data && !updateCarSubModelReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    }, [updateCarSubModelReducer])

    const deleteCarSubModelReducer = useAppSelector(
        (state) => state.deleteCarSubModelReducer
    )

    useEffect(() => {
        setIsLoading(deleteCarSubModelReducer.isFetching);
        if(deleteCarSubModelReducer.data && !deleteCarSubModelReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    }, [deleteCarSubModelReducer]);
    
    const columnsd = [
        { 
            field: 'code', 
            headerClassName: 'bg-gray ps-4 text-[#636578]',
            headerName: 'รหัสรุ่นย่อย', width: 100,
            sortable: false,
            cellClassName:'text-[#636578] font-normal',
        },
        { 
            field: 'car_brand_name', 
            headerClassName: 'bg-gray ps-4 text-[#636578]',
            headerName: 'ชื่อยี่ห้อ', width: 100,
            sortable: false,
            cellClassName:'text-[#636578] font-normal',
            renderCell: ({ row }) => (
                <div className='flex items-center'>
                    <span className='text-[#636578] font-normal '>{row.carBrand.name}</span>
                </div>
            )
        },
        {
            field: 'car_model_name',
            headerClassName: 'bg-gray text-[#636578]',
            headerName: 'รุ่น',
            sortable: false,
            renderCell: ({ row }) => (
                <div className='flex items-center'>
                    <span className='text-[#636578] font-normal '>{row.carModel.name}</span>
                </div>
            )
        },
        {
            field: 'year',
            headerClassName: 'bg-gray text-[#636578]',
            headerName: 'ปี',
    
            sortable: false,
            renderCell: ({ row }) => (
                <div className='flex items-center'>
                    <span className='text-[#636578] font-normal '>{row.year}</span>
                </div>
            )
        },

        { 
            field: 'name', 
            headerClassName: 'bg-gray ps-4 text-[#636578]',
            headerName: 'รุ่นย่อย', width: 100,
            sortable: false,
            flex: 1,
            cellClassName:'text-[#636578] font-normal',
        },
        
        {
            field: 'is_status',
            headerClassName: 'bg-gray text-[#636578]',
            cellClassName:'text-center',
            headerAlign: 'center',
            headerName: 'สถานะ',
            width: 150,
            sortable: false,
            renderCell: ({ row }) => {
                return <Button 
                className={` ${row.is_status ? 'bg-[#EEFBE5] text-[#72E128]' : 'bg-[#FFE4E6] text-[#F95057]' } p-0 w-[127px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    const {id,carBrand,carModel,...payloadData} = row;
                    dispatch(updateCarSubModel(row.id,{...payloadData,is_status:!row.is_status},{modalObj,setModalObj}));
                }}
                
                >{`${row.is_status ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }`}</Button>
            } 
        },
        {
            field: 'id',
            headerClassName: 'bg-gray text-[#636578]',
            headerAlign: 'center',
            headerName: 'จัดการ',
            width: 150,
            cellClassName:'text-center',
            sortable: false,
            renderCell: ({ row }) => (
                <>
                <Button 
                className={`bg-[#E9E9E9] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    setFormedit(row) 
                    setOpenEdit(true)
                }}
                >
                    <img src={EditIcon} className=" w-[16px] h-[16px]"  />
                </Button>
                <Button 
                className={`bg-[#F95057] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    setCarSubModel(prev => prev.filter(item => item.id !== row.id))
                    dispatch(deleteCarSubModel(row.id,row,{modalObj,setModalObj}));
                }}

                ><img src={DeleteIcon} className=" w-[19px] h-[19px]"  /></Button>
                </>
            )
        }
    ];

    const [filterText, setFilterText] = useState(null);
            
    const debouncedSearch = useMemo(
        () =>
            debounce((searchText) => {
            loadAll(1, pageModel.perPage, searchText || "")
            }, 300),
        []
    );

    useEffect(() => {
        if(filterText !== null) {
            debouncedSearch(filterText || "");
        }
    }, [filterText]);


    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [formedit, setFormedit] = React.useState({});
   

    
    return (
        <div className='max-w-[1070px]'>
                    
            <div className='flex items-center justify-end mb-4 px-4'>
        
                <div className='me-3 ' style={{minWidth:'300px'}}>
                    <input type='text' className='form-control form-st-4' placeholder='ค้นหาจากรหัส/ชื่อ/รุ่น/รุ่นย่อย' onKeyUp={e => setFilterText(e.target.value)} />
                </div>
                <Button 
                className={'rounded-[8px] h-[37px] w-[130px] text-[#FFF] bg-[#8955C1]' } 
                variant={'contained'}
                onClick={() => setOpen(true) }
                
                >
                    เพิ่มรุ่นย่อย
                </Button>
            </div>
            <div className="card border-0 bg-white rounded-[10px] overflow-hidden " style={{
                boxShadow: '0px 2px 10px 0px #4C4E6438'
            }}>
                <div className="card-body p-0">
                    
                    <DataGrid
                        getRowId={(row) => row.id}
                        checkboxSelection 
                        rows={carSubModel}
                        autoHeight={true}
                        columns={columnsd}
                        pagination
                        paginationMode="server"
                        paginationModel={{
                            page: pageModel.page - 1,
                            pageSize: pageModel.perPage
                        }}
                        onPaginationModelChange={(newPagination) => {
                        setPageModel(prev => ({
                            ...prev,
                            page: newPagination.page+1, 
                            pageSize: newPagination.pageSize
                        }))
                        loadAll(newPagination.page+1, newPagination.pageSize,filterText || "");
                        }}
                        rowsPerPage={pageModel.page}
                        rowCount={pageModel.total}
                        disableRowSelectionOnClick 
                        enableCellSelect={true}
                        pageSizeOptions={[5,10,15]}
                        slotProps={{ pagination: { labelRowsPerPage: 'จำนวนรายการต่อหน้า' } }}
                        disableMultipleRowSelection={false}
                        sx={{
                            boxShadow: 0,
                            border: 0,
                            fontWeight:900,
                            color:'#000000',
                            fontFamily: 'Sarabun',
                            fontSize:'14px',
                            borderRadius:0,
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiTablePagination-selectLabel': {
                                fontFamily: 'Sarabun',
                                marginBottom:'3px'
                            },
                            '& .MuiTablePagination-displayedRows': {
                                fontFamily: 'Sarabun',
                                marginBottom:'3px'
                            },
                            '& .MuiDataGrid-filler': {
                                background:'#F5F5F7'
                            },
                            '& .MuiDataGrid-columnHeader':{
                                background:'#F5F5F7'
                            },
                            '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root':{
                                color:'#4C4E64AD'
                            }
                        
                        }}
                    />
                
                </div>

            </div>

            <ModalCreate open={open} setOpen={setOpen} />

            <ModalEdit open={openEdit} setOpen={setOpenEdit} formedit={formedit} setCarSubModel={setCarSubModel} />
        </div>
    )
}


const ModalCreate = ({open,setOpen}) => {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();

    const [optionsCarBrand, setOptionsCarBrand ] = useState([]);
    const [optionsCarModel, setOptionsCarModel ] = useState([]);

    const [selectedCarBrand, setSelectedCarBrand] = useState(null);

    const getCarBrandListReducer = useAppSelector(
        (state) => state.getCarBrandListReducer
    );

    const getCarModelListReducer = useAppSelector(
        (state) => state.getCarModelListReducer
    );

    const loadAllOptions = () => {
        dispatch(getCarModelList());
        dispatch(getCarBrandList());
    }

    useEffect(() => {
            loadAllOptions()
    },[]);

    useEffect(() => {
        setIsLoading( 
            getCarBrandListReducer.isFetching ||
            getCarModelListReducer.isFetching
        );

        if(getCarBrandListReducer.data){
            const options = mapCarBrandOptions(getCarBrandListReducer.data.data);
            setOptionsCarBrand([...options]);
        }

        if(getCarModelListReducer.data){
            /* const options = mapCarModelOptions(getCarModelListReducer.data.data);
            setOptionsCarModel([...options]); */

            const carModels = getCarModelListReducer.data.data;
            const filteredModels = selectedCarBrand 
                ? carModels.filter(model => model.car_brand_id === selectedCarBrand) 
                : [];
            const options = mapCarModelOptions(filteredModels);
            setOptionsCarModel([...options]);
        }
    },[ getCarBrandListReducer,getCarModelListReducer,selectedCarBrand ]);

    const [optionsYear, setOptionsYear] = useState(
                Array.from({ length: new Date().getFullYear() - 1989 }, (_, i) => ({
                value: 1990 + i,
                label: 1990 + i
            }))
    );
    
    const schema = yup.object().shape({
        car_brand_id: yup.number().required(),
        car_model_id: yup.number().required(),
        name: yup.string().required(),
        year: yup.string().required(),
        is_status: yup.boolean().required(),
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [formdata, setFormdata] = useState({"code":"","name":"","year":"","car_brand_id":"","car_model_id":"","is_status":""})

    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
            <div className='flex items-center mb-7'>
                <div className='mr-auto text-lg'> เพิ่มรุ่นย่อย</div>
                <IconButton aria-label="" className='p-0' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Formik
                initialValues={formdata}
                validationSchema={schema}
                enableReinitialize
                onSubmit={async (values) => {
                    console.log('create-car-sub-model-values',values)
                    dispatch(createCarSubModel(values,{ modalObj,setModalObj }));
                    setOpen(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>

                    <Row className=''>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รหัสรุ่นย่อย </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='code'
                                placeholder="รหัสรุ่นย่อย" 
                                onChange={handleChange}
                                value={values.code}
                                className='form-st-1 border-[1px] border-[#4C4E6438]'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ชื่อรุ่นย่อย <span className='text-[#FF0000]'>*</span> </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='name'
                                placeholder="ชื่อรุ่นย่อย" 
                                onChange={handleChange}
                                value={values.name}
                                className='form-st-1'
                                isInvalid={!!errors.name}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>ชื่อยี่ห้อ <span className='text-[#FF0000]'>*</span></Form.Label>
                                <SelectS1
                                    as="select"
                                    name="car_brand_id"
                                    onChange={(e) => {
                                        setFieldValue('car_brand_id', e.value);
                                        setSelectedCarBrand(e.value);
                                        setFieldValue('car_model_id', "")
                                    }}
                                    value={optionsCarBrand.filter((item) => item.value === values.car_brand_id)}
                                    className={!!errors.car_brand_id ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsCarBrand}
                                    placeholder="กรุณาเลือกยี่ห้อ"
                                    isInvalid={!!errors.car_brand_id}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>ชื่อรุ่น <span className='text-[#FF0000]'>*</span></Form.Label>
                                <SelectS1
                                    as="select"
                                    name="car_model_id"
                                    onChange={(e) => {
                                        setFieldValue('car_model_id', e.value)
                                    }}
                                    value={optionsCarModel.filter((item) => item.value === values.car_model_id)}
                                    className={!!errors.car_model_id ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsCarModel/* .filter(model => model.car_brand_id === selectedCarBrand) */}
                                    placeholder="กรุณาเลือกรุ่น"
                                    isInvalid={!!errors.car_model_id}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ปี</Form.Label>
                                <SelectS1
                                    as="select"
                                    name="year"
                                    onChange={(e) => {
                                        setFieldValue('year', e.value)
                                    }}
                                    className={!!errors.year ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsYear}
                                    placeholder="กรุณาเลือกปี"
                                    isInvalid={!!errors.year}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานะ</Form.Label>
                                <div className='inlineForm p-2 '
                                    style={{
                                        border:!!errors.is_status ? '1px solid #dc3545' : 0,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Form.Check
                                        inline
                                        label="ปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-1`}
                                        checked={values.is_status !== "" && !values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',false)
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label="เปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-2`}
                                        checked={values.is_status !== "" && values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',true)
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            
                        </Col>
                    </Row>

                    <div className='flex justify-end mt-4'>
                        
                        <ButtonM2
                        onClick={() => setOpen(false)}
                        >ยกเลิก</ButtonM2>

                        <ButtonM1 
                        type="submit"  
                        >
                            บันทึก
                        </ButtonM1>
                    </div>
                    
                    </form>
                )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
}


const ModalEdit = ({open,setOpen,formedit,setCarSubModel}) => {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();

    const [optionsCarBrand, setOptionsCarBrand ] = useState([]);
    const [optionsCarModel, setOptionsCarModel ] = useState([]);

    const [selectedCarBrand, setSelectedCarBrand] = useState(null);

    const getCarBrandListReducer = useAppSelector(
        (state) => state.getCarBrandListReducer
    );

    const getCarModelListReducer = useAppSelector(
        (state) => state.getCarModelListReducer
    );

    const loadAllOptions = () => {
        dispatch(getCarModelList());
        dispatch(getCarBrandList());
    }

    useEffect(() => {
            loadAllOptions()
    },[]);

    useEffect(() => {
        setIsLoading( 
            getCarBrandListReducer.isFetching ||
            getCarModelListReducer.isFetching
        );

        if(getCarBrandListReducer.data){
            const options = mapCarBrandOptions(getCarBrandListReducer.data.data);
            setOptionsCarBrand([...options]);
        }

        if(getCarModelListReducer.data){
            /* const options = mapCarModelOptions(getCarModelListReducer.data.data);
            setOptionsCarModel([...options]); */

            const carModels = getCarModelListReducer.data.data;
            const filteredModels = selectedCarBrand 
                ? carModels.filter(model => model.car_brand_id === selectedCarBrand) 
                : [];
            const options = mapCarModelOptions(filteredModels);
            setOptionsCarModel([...options]);
        }
    },[ getCarBrandListReducer,getCarModelListReducer,selectedCarBrand ]);

    const [optionsYear, setOptionsYear] = useState(
                Array.from({ length: new Date().getFullYear() - 1989 }, (_, i) => ({
                value: 1990 + i,
                label: 1990 + i
            }))
    );
    
    const schema = yup.object().shape({
        car_brand_id: yup.number().required(),
        car_model_id: yup.number().required(),
        name: yup.string().required(),
        year: yup.string().required(),
        is_status: yup.boolean().required(),
    });

    const handleClose = () => setOpen(false);
    const [formdata, setFormdata] = useState()
    
    useEffect(() => {
        setFormdata(formedit)
        setSelectedCarBrand(formedit.car_brand_id);
        // console.log('EDIT',formedit);
    }, [formedit]);


    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
            <div className='flex items-center mb-7'>
                <div className='mr-auto text-lg'> จัดการรุ่น</div>
                <IconButton aria-label="" className='p-0' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Formik
                initialValues={formdata}
                validationSchema={schema}
                enableReinitialize
                onSubmit={async (values) => {
                    // alert('save')
                    const {id,carBrand,carModel,...payloadData} = values;
                    dispatch(updateCarSubModel(values.id,payloadData,( modalObj,setModalObj )));
                    setOpen(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>

                    <Row className=''>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รหัสรุ่นย่อย </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='code'
                                placeholder="รหัสรุ่นย่อย" 
                                onChange={handleChange}
                                value={values.code}
                                className='form-st-1 border-[1px] border-[#4C4E6438]'
                                disabled={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ชื่อรุ่นย่อย <span className='text-[#FF0000]'>*</span> </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='name'
                                placeholder="ชื่อรุ่นย่อย" 
                                onChange={handleChange}
                                value={values.name}
                                className='form-st-1'
                                isInvalid={!!errors.name}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>ชื่อยี่ห้อ <span className='text-[#FF0000]'>*</span></Form.Label>
                                <SelectS1
                                    as="select"
                                    name="car_brand_id"
                                    onChange={(e) => {
                                        setFieldValue('car_brand_id', e.value)
                                        setSelectedCarBrand(e.value);
                                        setFieldValue('car_model_id', "")
                                    }}
                                    className={!!errors.car_brand_id ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsCarBrand}
                                    placeholder="กรุณาเลือกยี่ห้อ"
                                    isInvalid={!!errors.car_brand_id}
                                    value={
                                        values.car_brand_id
                                        ? optionsCarBrand.find(option => option.value === values.car_brand_id)
                                        :(formdata?optionsCarBrand.find(option => option.value === values.car_brand_id)
                                        : null)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>ชื่อรุ่น <span className='text-[#FF0000]'>*</span></Form.Label>
                                <SelectS1
                                    as="select"
                                    name="car_model_id"
                                    onChange={(e) => {
                                        setFieldValue('car_model_id', e.value)
                                    }}
                                    key={selectedCarBrand || 'reset-key'}
                                    className={!!errors.car_model_id ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsCarModel}
                                    placeholder="กรุณาเลือกยี่ห้อ"
                                    isInvalid={!!errors.car_model_id}
                                    value={
                                        values.car_model_id
                                        ? optionsCarModel.find(option => option.value === values.car_model_id)
                                        :(formdata?optionsCarModel.find(option => option.value === values.car_model_id)
                                        : null)
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ปี</Form.Label>
                                <SelectS1
                                    as="select"
                                    name="year"
                                    onChange={(e) => {
                                        setFieldValue('year', e.value)
                                    }}
                                    className={!!errors.year ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsYear}
                                    placeholder="กรุณาเลือกปี"
                                    isInvalid={!!errors.year}
                                    value={
                                        optionsYear.filter(
                                            item => item.value === values.year,
                                        )
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานะ</Form.Label>
                                <div className='inlineForm p-2 '
                                    style={{
                                        border:!!errors.is_status ? '1px solid #dc3545' : 0,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Form.Check
                                        inline
                                        label="ปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-1`}
                                        checked={!values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',false)
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label="เปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-2`}
                                        checked={values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',true)
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            
                        </Col>
                    </Row>

                    <div className='flex  mt-4'>
                        <div className="mr-auto">
                            <ButtonM3

                            onClick={() => {
                                setCarSubModel(prev => prev.filter(item => item.id !== values.id))
                                setOpen(false)
                            }}
                            >ลบ</ButtonM3>
                        </div>
                        

                        <ButtonM2
                        onClick={() => setOpen(false)}
                        >ยกเลิก</ButtonM2>

                        <ButtonM1 
                        type="submit"  
                        >
                            บันทึก
                        </ButtonM1>
                    </div>
                    
                    </form>
                )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 438,
    bgcolor: 'background.paper',
    boxShadow: '0px 0px 20px 0px #4C4E6433',
    p: 3,
    borderRadius:'10px',
    zIndex:999999
    
};