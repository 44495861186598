// LoaderComponent.js
import React from 'react';

function GlobalLoader() {
  return (
    <div className="global-loader-overlay">
      <div className="spinner-border text-dark" role="status">
      </div>
    </div>
  );
}

export default GlobalLoader;
