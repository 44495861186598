import { useGlobalModalContext } from "contexts/GlobalModalContext";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function GlobalModal({ title, subTitle, btnText, ...props }) {
  const { modalObj, setModalObj } = useGlobalModalContext();
  const [theme, setTheme] = useState({
    icon: "/icons/error-icon.png",
  });
  useEffect(() => {
    if (modalObj.icon === "error") {
      setTheme({ ...theme, icon: "/icons/error-icon.png" });
    } else {
      setTheme({ ...theme, icon: "/icons/success-icon.png" });
    }
  }, [modalObj.icon]);

  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body className="global-modal">
        <div className="px-3 py-4">
          <div className="text-center mb-4">
            <img className="modal-icon mx-auto" src={theme.icon} alt="" />
          </div>
          <div className="text-center fs-22 tc-6">{title}</div>
          <div className="text-center fs-14 tc-7">{subTitle}</div>
        </div>
        {btnText && (
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                setModalObj({ ...modalObj, visible: false })
              }}
              className="modal-btn w-fit mb-2"
            >
              {btnText}
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
