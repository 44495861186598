import * as insuranceAction from "../../slices/master/insurance.slice";
import { http } from "../../../utils/http";
import { apiUrl, endPoint } from "../../../constants/server/api";

export const getAllInsurance = ({ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(insuranceAction.fetchData());

    try {
      let url = endPoint.INSURANCE;
            
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
      };

      const result = await http(config);
      dispatch(insuranceAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(insuranceAction.fetchDataFailure());
    }
  };
};
