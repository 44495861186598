import * as getCarModelListActions from "../../slices/master/getCarModelList.slice";
import { http } from "../../../utils/http";
import { endPoint } from "constants/server/api";

export const getCarModelList = () => {
  return async (dispatch) => {
    dispatch(getCarModelListActions.fetchData());

    try {
      const config = {
        method: "get",
        url:  `${endPoint.CAR_MODEL}/list`
      };

      const result = await http(config);
      dispatch(getCarModelListActions.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getCarModelListActions.fetchDataFailure());
    }
  };
};
