import * as createCarCodeActions from "../../slices/master/createCarCode.slice";
import { http } from "../../../utils/http";
import { endPoint } from "constants/server/api";

export const createCarCode = (payload,{ modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(createCarCodeActions.fetchData());

    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        method: "post",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        url: endPoint.CAR_CODE,
        data: payload
      };

      const result = await http(config);
      dispatch(createCarCodeActions.fetchDataSuccess(result.data));
      
    } catch (error) {
      if (http.isAxiosError(error) && error.response) {
        const statusCode = error.response.status;
        setModalObj({
          ...modalObj,
          visible: true,
          subTitle: `รหัส ${statusCode}`,
          navigateDirection: undefined,
          title: `${error.response.data.message}`
        });
      } else {
        setModalObj({
          ...modalObj,
          visible: true,
          subTitle: `รหัส 500`,
          navigateDirection: undefined,
          title: 'เกิดข้อผิดพลาด\nหรือเครือข่ายขัดข้อง'
        });
      }
      dispatch(createCarCodeActions.fetchDataFailure());
    }
  };
};

export const clearCreateCarCode = () => {
  return async (dispatch) => {
    dispatch(createCarCodeActions.resetState());
  };
};
