export function mapPlaceToOptions(msts) {
    return msts.map((item) => ({
        label: item.name,
        value: item.id
    }));
}

export function mapCarBrandOptions(msts) {
    return msts.map((item) => ({
        label: item.name,
        value: item.id,
    }));
}

export function mapCarModelOptions(msts) {
    return msts.map((item) => ({
        label: item.name,
        value: item.id,
        // car_brand_id: item.car_brand_id,
    }));
}

export function base64ToBlob(base64) {
    const binaryString = window.atob(base64.split(",")[1]); // Remove base64 header
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes]);
}