import React, { createContext, useContext, useState } from 'react';

const GlobalLoaderContext = createContext(undefined);

export const GlobalLoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = {
    isLoading,
    setIsLoading,
  };

  return <GlobalLoaderContext.Provider value={contextValue}>{children}</GlobalLoaderContext.Provider>;
};

export const useGlobalLoaderContext = () => {
  const context = useContext(GlobalLoaderContext);
  if (!context) {
    throw new Error('useGlobalLoaderContext must be used within an ErrorProvider');
  }
  return context;
};
