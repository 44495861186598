import React, { createContext, useContext, useState } from 'react';

const GlobalModalContext = createContext(undefined);

export const GlobalModalProvider = ({ children }) => {
  const [modalObj, setModalObj] = useState({
    visible: false,
    icon: 'error',
    title: 'เกิดข้อผิดพลาด',
    subTitle: 'รหัส 500',
    btnText: 'ตกลง',
    navigateDirection: undefined,
  });

  const contextValue = {
    modalObj,
    setModalObj
  };

  return <GlobalModalContext.Provider value={contextValue}>{children}</GlobalModalContext.Provider>;
};

export const useGlobalModalContext = () => {
  const context = useContext(GlobalModalContext);
  if (!context) {
    throw new Error('useGlobalModalContext must be used within an ErrorProvider');
  }
  return context;
};
