import * as carBrandAction from "../../slices/master/carBrand.slice";
import { http } from "../../../utils/http";
import { apiUrl, endPoint } from "../../../constants/server/api";

export const getAllCarBrand = ({ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(carBrandAction.fetchData());

    try {
      let url = endPoint.CAR_BRAND;
            
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
      };

      const result = await http(config);
      dispatch(carBrandAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(carBrandAction.fetchDataFailure());
    }
  };
};
