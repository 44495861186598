import * as deleteCarBrandActions from "../../slices/master/deleteCarBrand.slice";
import { http } from "utils/http";
import { endPoint } from "constants/server/api";

export const deleteCarBrand = (id, payload, { modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(deleteCarBrandActions.fetchData());


    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        method: "delete",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        url: `${endPoint.CAR_BRAND}/${id}`,
        data: payload
      };

      const result = await http(config);
      dispatch(deleteCarBrandActions.fetchDataSuccess(result.data));
      
    } catch (error) {
      if (http.isAxiosError(error) && error.response) {
        const statusCode = error.response.status;
        setModalObj({
          ...modalObj,
          visible: true,
          subTitle: `รหัส ${statusCode}`,
          navigateDirection: undefined,
          title: `${error.response.data.message}`
        });
      } else {
        setModalObj({
          ...modalObj,
          visible: true,
          subTitle: `รหัส 500`,
          navigateDirection: undefined,
          title: 'เกิดข้อผิดพลาด\nหรือเครือข่ายขัดข้อง'
        });
      }
      dispatch(deleteCarBrandActions.fetchDataFailure());
    }
  };
};

export const cleardeleteCarBrand = () => {
  return async (dispatch) => {
    dispatch(deleteCarBrandActions.resetState());
  };
};
