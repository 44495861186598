
import Form from 'react-bootstrap/Form';
import React, { useState,useEffect } from 'react';
import {useNavigate,useLocation } from "react-router-dom";
import { getAllYear } from "../../../redux/handlers/master/year.handler";
import { getAllCarModel } from "../../../redux/handlers/master/carModel.handler";
import { getAllCarSubModel } from "../../../redux/handlers/master/carSubModel.handler";
import { getAllInsuranceType } from "../../../redux/handlers/master/insuranceType.handler";
import { getAllCarCode } from "../../../redux/handlers/master/carCode.handler";
import { getAllLicensePlateProvince } from "../../../redux/handlers/master/licensePlateProvince.handler";
import { createCarInsurance,updateCarInsurance } from "../../../redux/handlers/master/carInsurance.handler";


import { useAppDispatch ,useAppSelector} from "../../../redux/store/configureStore";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import CurrencyFormat from 'react-currency-format';
import { getCarInsuranceById } from "../../../redux/handlers/master/carInsurance.handler";
import {BrandCar} from 'layouts/BrandSelect';
import Text from 'fragments/Text';
import { SelectS1 } from 'fragments/Select';
export function  InsurancePremiumFilter() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const car_insurance_id = searchParams.get('car_insurance_id');
    const [dataselected, setDataselected] = useState({
        "carBrand":null,
        "car_year":null,
        "carModel":null,
        "carSubModel":null,
        "insuranceType":null,
        "original_insurance_value":null,
        "current_insurance_value":null,
        "licensePlateProvince":null,
        "carCode":null,
    });

    const [year, setYear] = useState([])
    const [model, setModel] = useState([])
    const [submodel, setSubModel] = useState([])
    const [insurancetype, setInsuranceType] = useState([])
    const [province, setProvince] = useState([])
    const [code, setCode] = useState([])
    const [fund, setFund] = useState()


    useEffect(()=> {
        dispatch(getCarInsuranceById(car_insurance_id));
    },[car_insurance_id])


    const carInsuranceReducer = useAppSelector(
        (state) => state.carInsuranceReducer
      );

      useEffect(() => {
        if (carInsuranceReducer.data) {
            let data = carInsuranceReducer.data.data;
            let newData = { ...data };  // หรือใช้ Object.assign({}, data);

            newData.car_year = {
                year: newData.year
            };
            dispatch(getAllYear());
            dispatch(getAllCarModel(newData.year,data.carBrand.id));
            dispatch(getAllCarSubModel(newData.carModel.id));
            dispatch(getAllInsuranceType());
            dispatch(getAllLicensePlateProvince());
            dispatch(getAllLicensePlateProvince());
            dispatch(getAllCarCode());
            setDataselected(newData);
            
        }
      }, [carInsuranceReducer]);


    const getYear = (brand) => {
        dispatch(getAllYear());
    }

    const getModel = (year,brand) => {

        dispatch(getAllCarModel(year,brand));
    }

    const getSubModal = (model) => {
        dispatch(getAllCarSubModel(model));
    }

    const getInsuranceType = (brand,year,model,submodel) => {
        dispatch(getAllInsuranceType());
    }

    const getProvince = () => {
        dispatch(getAllLicensePlateProvince());
    }
    const getCode = () => {
        dispatch(getAllCarCode());
    }


    const handleSubmit = () => {
        let data = { 
            "user_id": 1,
            "car_brand_id": dataselected.carBrand.id,
            "car_model_id": dataselected.carModel.id,
            "car_submodel_id": dataselected.carSubModel.id,
            "insurance_type_id": dataselected.insuranceType.id,
            "license_plate_province_id": dataselected.licensePlateProvince.id,
            "car_code_id": dataselected.carCode.id,
            "year": dataselected.car_year.year,
            "original_insurance_value": dataselected.original_insurance_value,
            "current_insurance_value": dataselected.current_insurance_value,
            "car_insurance_status_id": 1,}
        if (car_insurance_id) {
            dispatch(updateCarInsurance(car_insurance_id,data,navigate)); 
        } else {
            dispatch(createCarInsurance(data,navigate)); 
           
        }
        
   
    }

    const yearReducer = useAppSelector(
        (state) => state.yearReducer
      );
    const carModelReducer = useAppSelector(
        (state) => state.carModelReducer
      );
    const carSubModelReducer = useAppSelector(
        (state) => state.carSubModelReducer
     );
     const insuranceTypeReducer = useAppSelector(
        (state) => state.insuranceTypeReducer
     );

     const carCodeReducer = useAppSelector(
        (state) => state.carCodeReducer
      );

      const licensePlateProvinceReducer = useAppSelector(
        (state) => state.licensePlateProvinceReducer
      );
      
     useEffect(() => {
        if (yearReducer.data) {
            setYear(yearReducer.data.data);
        }
      }, [yearReducer]);

    useEffect(() => {
        if (carModelReducer.data) {
            setModel(carModelReducer.data.data);
        }
      }, [carModelReducer]);

      useEffect(() => {
        if (carSubModelReducer.data) {
            setSubModel(carSubModelReducer.data.data);
        }
      }, [carSubModelReducer]);

      useEffect(() => {
        if (insuranceTypeReducer.data) {
            setInsuranceType(insuranceTypeReducer.data.data);
        }
      }, [insuranceTypeReducer]);

      useEffect(() => {
        if (carCodeReducer.data) {
            setCode(carCodeReducer.data.data);
        }
      }, [carCodeReducer]);

      useEffect(() => {
        if (licensePlateProvinceReducer.data) {
            setProvince(licensePlateProvinceReducer.data.data);
        }
      }, [licensePlateProvinceReducer]);

    useEffect(() => {
        if(location.state && location.state.formdata){
            setDataselected(location.state.formdata)
        }
    }, [location])

    return (
        <div >
            <div className="fs-20 tc-7 className='ps-5' mb-4 lh-1">ภาคสมัครใจ </div>

            <div className='row'>
                <div className='col-auto'>
                    <div className='card' style={{maxWidth:"792px",border:0}}>
                        <div className='card-body'>
                            <div className="fs-20 tc-7 className='ps-5' mb-4 lh-1">เลือกยี่ห้อรถ </div>
                            
                            <BrandCar 
                                dataObj={{
                                    dataselected,
                                    setDataselected
                                }}
                                handleChangeBrand={(carBrand) => {
                                    getYear(carBrand)
                                }}
    
                            />

                            <div className='py-4'>
                                <Row className='gy-3'>
                                    <Col lg="6">
                                        <SelectS1
                                            as="select"
                                            name="year"
                                            onChange={(e) => {
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    car_year:e,
                                                    carModel:null,
                                                    carSubModel:null,
                                                    insuranceType:null,
                                                    original_insurance_value:null,
                                                    current_insurance_value:null,
                                                    licensePlateProvince:null,
                                                    carCode:null

                                                }))
                                                getModel(e.year,dataselected.carBrand.id)
                                            }}
                                            className="w-100 select-st-1  text-start"
                                            options={year}
                                            getOptionLabel={(option) => option.year}
                                            getOptionValue={(option) => option.year}
                                            placeholder="เลือกปี*"
                                            value={year.filter((i) => dataselected.car_year && dataselected.car_year.year == i.year)}
                                            isDisabled={!dataselected.carBrand}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <SelectS1
                                            as="select"
                                            name="model"
                                            onChange={(e) => {
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    carModel:e,
                                                    carSubModel:null,
                                                    insuranceType:null,
                                                    original_insurance_value:null,
                                                    current_insurance_value:null,
                                                    licensePlateProvince:null,
                                                    carCode:null
                                                }))
                                                getSubModal(e.id)
                                            }}
                                            className="w-100 select-st-1  text-start"
                                            options={model}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder="เลือกรุ่นรถยนต์*"
                                            value={model.filter((i) => dataselected.carModel && dataselected.carModel.id == i.id)}
                                            isDisabled={!dataselected.carBrand || !dataselected.car_year}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <SelectS1
                                            as="select"
                                            name=""
                                            onChange={(e) => {
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    carSubModel:e,
                                                    insuranceType:null,
                                                    original_insurance_value:null,
                                                    current_insurance_value:null,
                                                    licensePlateProvince:null,
                                                    carCode:null
                                                }))
                                                getInsuranceType(dataselected.car_year.year,dataselected.carBrand.id,dataselected.carModel.id,e.id)
                                            }}
                                            className="w-100 select-st-1  text-start"
                                            options={submodel}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder="เลือกรุ่นย่อยรถยนต์*"
                                            value={submodel.filter((i) => dataselected.carSubModel && dataselected.carSubModel.id == i.id)}
                                            isDisabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <SelectS1
                                            as="select"
                                            name=""
                                            onChange={(e) => {
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    insuranceType:e,
                                                    licensePlateProvince:null,
                                                    carCode:null,
                                                    current_insurance_value:500000
                                                }))
                                                getProvince()
                                                getCode();

                                                
                                            }}
                                            className="w-100 select-st-1  text-start"
                                            options={insurancetype}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder="เลือกประเภทประกัน*"
                                            value={insurancetype.filter((i) => dataselected.insuranceType && dataselected.insuranceType.id == i.id)}
                                            isDisabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel}
                                        />
                                    </Col>

                                    <Col lg="6">
                                        <Form.Label className='fs-14' htmlFor="">ทุนประกันเดิม (ถ้ามี)</Form.Label>
                                        <CurrencyFormat 
                                            className='form-st-1 form-control ' 
                                            value={ dataselected.original_insurance_value ? dataselected.original_insurance_value : ''}
                                            displayType={'input'} 
                                            thousandSeparator={true} 
                                            prefix={''} 
                                            decimalScale={2} 
                                            fixedDecimalScale={true}
                                            placeholder='ทุนประกันเดิม (ถ้ามี)'
                                            disabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel || !dataselected.insuranceType  }
                                            onValueChange={(values) => {
                                                const {formattedValue, value} = values;
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    original_insurance_value: value
                                                }))
                                            }}
                                        
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <Form.Label className='fs-14' htmlFor="">ทุนประกัน</Form.Label>

                                        <div className='d-flex align-items-center'>
                                            <Button className='btn btn-mius'
                                            disabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel  || !dataselected.insuranceType}
                                            onClick={() => {
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    current_insurance_value: Number(prev.current_insurance_value)-10000
                                                }))
                                            }}
                                            ></Button>
                                            <div className='flex-fill px-4'>
                                                <CurrencyFormat 
                                                className='form-st-1 form-control text-center' 
                                                value={ dataselected.current_insurance_value ? dataselected.current_insurance_value : ''} displayType={'input'} 
                                                thousandSeparator={true} 
                                                prefix={''} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                                readOnly
                                                disabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel  || !dataselected.insuranceType}
                                                />
                                            </div>
                                            <Button className='btn btn-plus' 
                                            disabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel  || !dataselected.insuranceType}
                                            onClick={() => {
                                                setDataselected((prev) => ({
                                                    ...prev,
                                                    current_insurance_value: Number(prev.current_insurance_value)+10000
                                                }))
                                            }}
                                            ></Button>
                                        </div>
                                        
                                    </Col>
                                    
                                    <Col lg="6">
                                        <SelectS1
                                            as="select"
                                            name="province"
                                            onChange={(e) => {
                                                setDataselected((prev) => ({...prev,licensePlateProvince:e}))
                                            }}
                                            className="w-100 select-st-1  text-start"
                                            options={province}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder="จังหวัด*"
                                            value={province.filter((i) => dataselected.licensePlateProvince && dataselected.licensePlateProvince.id == i.id)}
                                            isDisabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel  || !dataselected.insuranceType}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <SelectS1
                                            as="select"
                                            name="storeH"
                                            onChange={(e) => {
                                                setDataselected((prev) => ({...prev,carCode:e}))
                                            }}
                                            className="w-100 select-st-1  text-start"
                                            options={code}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder="รหัสรถยนต์*"
                                            value={code.filter((i) => dataselected.carCode && dataselected.carCode.id == i.id)}
                                            isDisabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel  || !dataselected.insuranceType}
                                        />
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                    <div className='py-5'></div>
                </div> 
                <div className='col-auto'>
                    <div className='card card-st-2' style={{width:"320px",border:0}}>
                        <div className='card-header'>
                            รายละเอียดรถยนต์
                            <img src='/images/image_car.png' />
                        </div>
                        <div className='card-body'>
                            <Table  borderless >
                                <tbody>
                                { dataselected.carBrand ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>ยี่ห้อ</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.carBrand.name}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.car_year ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>ปี</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.car_year.year}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }


                                { dataselected.carModel ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>รุ่นรถ</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.carModel.name}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.carSubModel ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>รุ่นย่อย</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.carSubModel.name}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.insuranceType ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>ประเภท</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.insuranceType.name}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.original_insurance_value ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>ทุนประกันเดิม</Text>
                                    </td>
                                    <td>
                                        <CurrencyFormat 
                                            value={dataselected.original_insurance_value}
                                            displayType={'text'} 
                                            thousandSeparator={true} 
                                            prefix={''} 
                                            decimalScale={2} 
                                            fixedDecimalScale={true}
                                            style={{color:"#8955C1"}}
                                        />  
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.current_insurance_value ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>ทุนประกัน</Text>
                                    </td>
                                    <td>
                                        <CurrencyFormat 
                                            value={dataselected.current_insurance_value}
                                            displayType={'text'} 
                                            thousandSeparator={true} 
                                            prefix={''} 
                                            decimalScale={2} 
                                            fixedDecimalScale={true}
                                            style={{color:"#8955C1"}}
                                        />  
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.licensePlateProvince ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>จังหวัด</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.licensePlateProvince.name}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }

                                { dataselected.carCode ? (
                                <tr>
                                    <td width={'40%'}>
                                        <Text>รหัสรถยนต์</Text>
                                    </td>
                                    <td>
                                        <Text c='#8955C1'>{dataselected.carCode.name}</Text>
                                            
                                    </td>
                                </tr>
                                ) : '' }
                                </tbody>
                            </Table> 


                            <div className='text-center'>
                                <Button 
                                onClick={() => handleSubmit()}
                                className='btn btn-st-1 fs-16'
                                disabled={!dataselected.carBrand || !dataselected.car_year  || !dataselected.carModel || !dataselected.carSubModel || !dataselected.licensePlateProvince || !dataselected.carCode}
                                >ออกใบเสนอราคา</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}