
import Text from 'fragments/Text';
import React, { useState,useEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Image from 'react-bootstrap/Image';

import Chip from '@mui/material/Chip';

import DeleteIcon from 'assets/icon/solar_trash-bin-trash-outline.svg'
import EditIcon from 'assets/icon/cil_pencil.svg'

import {CarBrand} from "layouts/setting/CarBrand"
import {CarModel} from "layouts/setting/CarModel"
import {CarSubModel} from "layouts/setting/CarSubModel"
import {CarColor} from "layouts/setting/CarColor"
import {CarCode} from "layouts/setting/CarCode"
import {Province} from "layouts/setting/Province"
import {InsuranceType} from "layouts/setting/InsuranceType"
import {Insurance} from "layouts/setting/Insurance"
import { useAppDispatch } from '../../../redux/store/configureStore';
export function SettingCar () {

    
    const [tab, setTab] = React.useState('brand');


    return (
        <>

        
            <div className="ps-5" >
                <div className="text-base text-[#2F2F2F] tc-7  mb-4">จัดการข้อมูลมาสเตอร์ </div>

                
                <div className='mb-5'>
                    <ButtonTab name={"ยี่ห้อ"} value="brand" tab={tab} setTab={setTab} />
                    <ButtonTab name={"รุ่น"} value="model" tab={tab} setTab={setTab} />
                    <ButtonTab name={"รุ่นย่อย"} value="sub_model" tab={tab} setTab={setTab} />
                    <ButtonTab name={"ประเภทประกัน"} value="insurance_type" tab={tab} setTab={setTab} />
                    <ButtonTab name={"บริษัทประกัน"} value="insurance" tab={tab} setTab={setTab} />
                    <ButtonTab name={"สี"} value="color" tab={tab} setTab={setTab} />
                    <ButtonTab name={"รหัสรถ"} value="car_code" tab={tab} setTab={setTab} />
                    <ButtonTab name={"จังหวัด (ป้าย)"} value="province" tab={tab} setTab={setTab} />
                </div>
                
                <div>
                    {
                        tab == 'brand' ?  <CarBrand /> : ''
                    }
                    {
                        tab == 'model' ?  <CarModel /> : ''
                    }
                    {
                        tab == 'sub_model' ?  <CarSubModel /> : ''
                    }
                    {
                        tab == 'color' ?  <CarColor /> : ''
                    }
                    {
                        tab == 'car_code' ?  <CarCode /> : ''
                    }
                    {
                        tab == 'province' ?  <Province /> : ''
                    }
                    {
                        tab == 'insurance_type' ?  <InsuranceType /> : ''
                    }
                    {
                        tab == 'insurance' ?  <Insurance /> : ''
                    }



                    
                </div>
            </div>
        </>
    )
}

const ButtonTab = ({tab,setTab,name,value}) => {
    return (
        <Button 
        className={`${tab == value ? 'rounded-[8px] h-[34px] text-[#FFF] bg-[#8955C1]' : 'text-[#9C9C9C] h-[34px]'  }` } 
        variant={`${tab == value ? 'contained' : 'text' }`}
        onClick={() => setTab(value) }
        >
            {name}
        </Button>
    )
}



