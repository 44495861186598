
import Button from '@mui/material/Button';

export const  ButtonM1 = ({children,className,type="button"}) => {

    return (
        <Button 
            type="type"
            className={'rounded-[8px] h-[37px] w-[82px] shadow-none text-[#FFF] bg-[#8955C1]' } 
            variant={'contained'} >
                {children}
        </Button>
    )
}

export const  ButtonM2 = ({children,className,type="button",onClick}) => {

    return (
        <Button 
        onClick={onClick}
        type={type}
        className={'rounded-[8px] shadow-none h-[37px] w-[84px] mr-4 text-[#6D788D] border-[1px] border-[#6D788D80] bg-transparent' } 
        variant={'outlined'}
        >
            {children}
        </Button>
    )
}

export const  ButtonM3 = ({children,className,type="button",onClick}) => {

    return (
        <Button 
        onClick={onClick}
        type={type}
        className={'rounded-[8px] shadow-none h-[37px] w-[62px] mr-4 text-[#F95057] border-[1px] border-[#F95057] bg-transparent' } 
        variant={'outlined'}
        >
            {children}
        </Button>
    )
}
