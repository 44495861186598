import React, { useState } from 'react';


import { Layout } from "./layouts/layout";
import { InsurancePremiumFilter } from "components/pages/insurance/formSearch";
import { InsuranceList } from "components/pages/insurance/insuranceList";
import { CompareDetail } from "components/pages/insurance/compare";
import { CustomerSelect } from "components/pages/insurance/customer";
import { Quotation } from "components/pages/insurance/quotation";
import { Buy } from "components/pages/insurance/buy";


import { SettingCar } from "components/pages/setting/Car";

import { Login } from "components/pages/login";


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams
} from "react-router-dom";
import { useGlobalLoaderContext } from 'contexts/GlobalLoaderContext';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import GlobalLoader from 'layouts/GlobalLoader';
import GlobalModal from 'layouts/GlobalModal';

function App() {
  const { isLoading } = useGlobalLoaderContext();
  const { modalObj, setModalObj } = useGlobalModalContext();

  
  return (
    <>
    {isLoading && <GlobalLoader />}
      <GlobalModal
        show={modalObj.visible}
        title={modalObj.title}
        subTitle={modalObj.subTitle}
        btnText={modalObj.btnText}
        onHide={() => setModalObj({ ...modalObj, visible: false })}
      />
    <Routes>
      
      <Route path="/" element={<Login />}> </Route>
      
      <Route path="/" element={<Layout />}>

        <Route path="/insurance/premium" element={<InsurancePremiumFilter />} />
        <Route path="/insurance/premium/list" element={<InsuranceList />} />
        <Route path="/insurance/premium/compare" element={<CompareDetail />} />
        <Route path="/insurance/premium/customer" element={<CustomerSelect />} />
        <Route path="/insurance/premium/quotation" element={<Quotation />} />
        <Route path="/insurance/premium/buy" element={<Buy />} />

        <Route path="/setting/car" element={<SettingCar />} />

      </Route>
    </Routes>
    </>
  );
  
}




function Home() {
  return (
    <div></div>
  );
}
function Layoutadmin() {
  return (
    <div className="page">
      <Layout />
      <div className="main-content app-content">

      </div>
    </div>

  );
}
export default App;
