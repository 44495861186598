import React, { useState,useEffect } from 'react';
import Text from 'fragments/Text';
import Image from 'react-bootstrap/Image';
import { getAllCarBrand } from "../redux/handlers/master/carBrand.handler";
import { useAppDispatch ,useAppSelector} from "../redux/store/configureStore";
export const  BrandCar = ({dataObj,handleChangeBrand}) => {
    const dispatch = useAppDispatch();
    const [brand, setBrand] = useState(false);

    const [filterText, setFilterText] = useState("");

    let filteredItems;
    if (brand !== undefined && brand.length > 0) {
      filteredItems = brand.filter((item) => {
        const matchName = item.name
          .toLowerCase()
          .includes(filterText.toLowerCase());
        return matchName;
      });
    }

    useEffect(() => {
        const queryParams = {
            page: "",
            perPage: "",
        } 
        dispatch(getAllCarBrand({ queryParams }));
    }, []);


    const carBrandReducer = useAppSelector(
        (state) => state.carBrandReducer
     );

     useEffect(() => {
        if (carBrandReducer.data) {
            setBrand(carBrandReducer.data.data);
        }
      }, [carBrandReducer]);

    return (
        <>
            <div className='px-3'>
                <input type='text' className="form-control input-search"  placeholder='ค้นหายี่ห้อรถ' onKeyUp={(e) => setFilterText(e.target.value)} />
            </div>
            <div className="p-1"
                style={{overflowX:'scroll'}}
            >
                <div className='d-inline-block  ' style={{whiteSpace : 'nowrap'}}>
                    {
                        filteredItems && filteredItems.map((item,index) => {

                            return (
                                <span key={index}>
                                    { 
                                        index == Math.ceil(brand.length/2) ? <div></div> : ''

                                    }
                                
                                    <div className={`p-3 d-inline-block `}   >
                                        <BrandItem item={item} key={index} dataObj={dataObj} handleChangeBrand={handleChangeBrand}  />
                                    </div>
                                </span>
                            )                            
                        }
                        )
                    }
                </div>
            </div>
            <div className="text-end mt-2">
                <Text s={14} c='#1A1A1A'>สามารถเลื่อนไปด้านขวาได้  </Text>
            </div>
        </>
    )

}




const BrandItem = ({item,dataObj,handleChangeBrand}) => {

    const handleChange = (brand) => {
        dataObj.setDataselected((prev) => (
            {
                ...prev,
                    carBrand:brand,
                    car_year:null,
                    carModel:null,
                    carSubModel:null,
                    insuranceType:null,
                    original_insurance_value:null,
                    current_insurance_value:null,
                    licensePlateProvince:null,
                    carCode:null
            }
        ))

        handleChangeBrand(brand)
    }
    return (
        <>
            <div
                className={`d-flex align-items-center justify-content-center ${  dataObj.dataselected.carBrand && item.id == dataObj.dataselected.carBrand.id ? 'selectedbox' : '' }   `}
                style={{width:'91px',height:'91px',boxShadow: '0px 1px 6px 0px #D8E7FD80',cursor:'pointer'}}
                onClick={() => {
                    handleChange(item)
                } }
            >
                    <Image src={`${item.image_path}`} width={'67px'} />
            </div>
        </>
    )
}


