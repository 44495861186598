import { combineReducers } from "@reduxjs/toolkit";

// auth

// master
import getAllTestTableReducer from "./master/getAllTestTable.slice";
import carBrandReducer from "./master/carBrand.slice";
import createCarBrandReducer from "./master/createCarBrand.slice";
import updateCarBrandReducer from "./master/updateCarBrand.slice";
import deleteCarBrandReducer from "./master/deleteCarBrand.slice";
import getCarBrandListReducer from "./master/getCarBrandList.slice";
import carModelReducer from "./master/carModel.slice";
import createCarModelReducer from "./master/createCarModel.slice";
import updateCarModelReducer from "./master/updateCarModel.slice";
import deleteCarModelReducer from "./master/deleteCarModel.slice";
import getCarModelListReducer from "./master/getCarModelList.slice";
import carSubModelReducer from "./master/carSubModel.slice";
import createCarSubModelReducer from "./master/createCarSubModel.slice";
import updateCarSubModelReducer from "./master/updateCarSubModel.slice";
import deleteCarSubModelReducer from "./master/deleteCarSubModel.slice";
import insuranceTypeReducer from "./master/insuranceType.slice";
import createInsuranceTypeReducer from "./master/createInsuranceType.slice";
import updateInsuranceTypeReducer from "./master/updateInsuranceType.slice";
import deleteInsuranceTypeReducer from "./master/deleteInsuranceType.slice";
import yearReducer from "./master/year.slice";
import carCodeReducer from "./master/carCode.slice";
import createCarCodeReducer from "./master/createCarCode.slice";
import updateCarCodeReducer from "./master/updateCarCode.slice";
import deleteCarCodeReducer from "./master/deleteCarCode.slice";
import licensePlateProvinceReducer from "./master/licensePlateProvince.slice";
import createLicensePlateProvinceReducer from "./master/createLicensePlateProvince.slice";
import updateLicensePlateProvinceReducer from "./master/updateLicensePlateProvince.slice";
import deleteLicensePlateProvinceReducer from "./master/deleteLicensePlateProvince.slice";
import carInsuranceReducer from "./master/carInsurance.slice";
import placeReducer from "./master/place.slice";
import insuranceReducer from "./master/insurance.slice";
import createInsuranceReducer from "./master/createInsurance.slice";
import updateInsuranceReducer from "./master/updateInsurance.slice";
import deleteInsuranceReducer from "./master/deleteInsurance.slice";
import insuranceAddonServiceReducer from "./master/insuranceAddonService.slice";
import insuranceCompanyReducer from "./master/insuranceCompany.slice";
import colorReducer from "./master/color.slice";
import createColorReducer from "./master/createColor.slice";
import updateColorReducer from "./master/updateColor.slice";
import deleteColorReducer from "./master/deleteColor.slice";


// logic

const rootReducer = combineReducers({
  // master
  carBrandReducer: carBrandReducer,
  createCarBrandReducer: createCarBrandReducer,
  updateCarBrandReducer: updateCarBrandReducer,
  deleteCarBrandReducer: deleteCarBrandReducer,
  getCarBrandListReducer: getCarBrandListReducer,
  getAllTestTableReducer: getAllTestTableReducer,
  carModelReducer: carModelReducer,
  createCarModelReducer: createCarModelReducer,
  updateCarModelReducer: updateCarModelReducer,
  deleteCarModelReducer: deleteCarModelReducer,
  getCarModelListReducer: getCarModelListReducer,
  carSubModelReducer: carSubModelReducer,
  createCarSubModelReducer: createCarSubModelReducer,
  updateCarSubModelReducer: updateCarSubModelReducer,
  deleteCarSubModelReducer: deleteCarSubModelReducer,
  carCodeReducer: carCodeReducer,
  createCarCodeReducer: createCarCodeReducer,
  updateCarCodeReducer: updateCarCodeReducer,
  deleteCarCodeReducer: deleteCarCodeReducer,
  licensePlateProvinceReducer: licensePlateProvinceReducer,
  createLicensePlateProvinceReducer: createLicensePlateProvinceReducer,
  updateLicensePlateProvinceReducer: updateLicensePlateProvinceReducer,
  deleteLicensePlateProvinceReducer: deleteLicensePlateProvinceReducer,
  insuranceTypeReducer: insuranceTypeReducer,
  createInsuranceTypeReducer: createInsuranceTypeReducer,
  updateInsuranceTypeReducer: updateInsuranceTypeReducer,
  deleteInsuranceTypeReducer: deleteInsuranceTypeReducer,
  carInsuranceReducer: carInsuranceReducer,
  placeReducer: placeReducer,
  insuranceAddonServiceReducer: insuranceAddonServiceReducer,
  insuranceReducer: insuranceReducer,
  createInsuranceReducer: createInsuranceReducer,
  updateInsuranceReducer: updateInsuranceReducer,
  deleteInsuranceReducer: deleteInsuranceReducer,
  insuranceCompanyReducer: insuranceCompanyReducer,
  colorReducer: colorReducer,
  createColorReducer: createColorReducer,
  updateColorReducer: updateColorReducer,
  deleteColorReducer: deleteColorReducer,
  yearReducer: yearReducer,
});
export default rootReducer;
