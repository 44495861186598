import React, { useState,useEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Image from 'react-bootstrap/Image';
import DeleteIcon from 'assets/icon/solar_trash-bin-trash-outline.svg'
import EditIcon from 'assets/icon/cil_pencil.svg'

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import zIndex from '@mui/material/styles/zIndex';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SelectS1 } from 'fragments/Select';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import { ButtonM1,ButtonM2,ButtonM3 } from 'fragments/ButtonModal';


import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { useGlobalLoaderContext } from 'contexts/GlobalLoaderContext';
import { getAllPlace } from '../../redux/handlers/master/place.handler';
import { getAllInsuranceType } from '../../redux/handlers/master/insuranceType.handler';
import { mapPlaceToOptions } from 'utils/HelperFunction';
import { createInsuranceType } from '../../redux/handlers/master/createInsuranceType.handler';
import { updateInsuranceType } from '../../redux/handlers/master/updateInsuranceType.handler';
import { deleteInsuranceType } from '../../redux/handlers/master/deleteInsuranceType.handler';
import { debounce } from '@mui/material';
import { useMemo } from 'react';
export function InsuranceType() {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();
    const [insType, setInsType] = useState([])

    const [pageModel,setPageModel] = useState({
            page: 1,
            perPage: 10,
            total: 0
    });
    const insuranceTypeReducer = useAppSelector(
        (state) => state.insuranceTypeReducer
    );

    const loadAll = (page,perPage,searchText) => {
        const queryParams = {
            page: page,
            perPage: perPage,
            searchText: searchText,
        } 
        dispatch(getAllInsuranceType({ queryParams, modalObj, setModalObj }));
    };

    useEffect(() => {
        loadAll(1,pageModel.perPage,filterText || "");
    },[]);

    useEffect(() => {
        setIsLoading( 
            insuranceTypeReducer.isFetching
        );

        if(insuranceTypeReducer.data && insuranceTypeReducer.data.data){
            const meta = insuranceTypeReducer.data.data.meta;
            setPageModel({
                page: meta.currentPage,
                perPage: meta.perPage,
                total: meta.total
            });
            setInsType(insuranceTypeReducer.data.data.data);
        }

    },[ insuranceTypeReducer ]);

    const createInsuranceTypeReducer = useAppSelector(
        (state) => state.createInsuranceTypeReducer
    );

    useEffect(() => {
        setIsLoading(createInsuranceTypeReducer.isFetching);

        if(createInsuranceTypeReducer.data && !createInsuranceTypeReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    },[ createInsuranceTypeReducer ]);

    const updateInsuranceTypeReducer = useAppSelector(
        (state) => state.updateInsuranceTypeReducer
    );

    useEffect(() => {
        setIsLoading(updateInsuranceTypeReducer.isFetching);

        if(updateInsuranceTypeReducer.data && !updateInsuranceTypeReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    },[ updateInsuranceTypeReducer ]);

    const deleteInsuranceTypeReducer = useAppSelector(
        (state) => state.deleteInsuranceTypeReducer
    );

    useEffect(() => {
        setIsLoading(deleteInsuranceTypeReducer.isFetching);

        if(deleteInsuranceTypeReducer.data && !deleteInsuranceTypeReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    },[ deleteInsuranceTypeReducer ]);
    
    const columnsd = [
        { 
            field: 'code', 
            headerClassName: 'bg-gray ps-4 text-[#636578]',
            headerName: 'รหัสประเภทประกัน', width: 150,
            sortable: false,
            cellClassName:'text-[#636578] font-normal',
        },
        {
            field: 'name',
            headerClassName: 'bg-gray text-[#636578]',
            headerName: 'ชื่อประเภทประกัน',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
                <div className='flex items-center'>
                    <span className='text-[#636578] font-normal '>{row.name}</span>
                </div>
            )
        },
        {
            field: 'is_status',
            headerClassName: 'bg-gray text-[#636578]',
            cellClassName:'text-center',
            headerAlign: 'center',
            headerName: 'สถานะ',
            width: 150,
            sortable: false,
            renderCell: ({ row }) => {
                return <Button 
                className={` ${row.is_status ? 'bg-[#EEFBE5] text-[#72E128]' : 'bg-[#FFE4E6] text-[#F95057]' } p-0 w-[127px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    const {id,place,...payloadData} = row;
                    dispatch(updateInsuranceType(row.id,{...payloadData,is_status:!row.is_status}, { modalObj,setModalObj }));
                }}
                
                >{`${row.is_status ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }`}</Button>
        } 
        },
        {
            field: 'id',
            headerClassName: 'bg-gray text-[#636578]',
            headerAlign: 'center',
            headerName: 'จัดการ',
            width: 150,
            cellClassName:'text-center',
            sortable: false,
            renderCell: ({ row }) => (
                <>
                <Button 
                className={`bg-[#E9E9E9] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    setFormedit(row) 
                    setOpenEdit(true)
                }}
                >
                    <img src={EditIcon} className=" w-[16px] h-[16px]"  />
                </Button>
                <Button 
                className={`bg-[#F95057] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    setInsType(prev => prev.filter(item => item.id !== row.id))
                    dispatch(deleteInsuranceType(row.id,row,{ modalObj, setModalObj }));
                }}

                ><img src={DeleteIcon} className=" w-[19px] h-[19px]"  /></Button>
                </>
            )
        }
    ];

    const [filterText, setFilterText] = useState(null);
    
    const debouncedSearch = useMemo(
        () =>
            debounce((searchText) => {
            loadAll(1, pageModel.perPage, searchText || "")
            }, 300),
        []
    );

    useEffect(() => {
        if(filterText !== null) {
            debouncedSearch(filterText || "");
        }
    }, [filterText]);


    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [formedit, setFormedit] = React.useState({});


    
    return (
        <div className='max-w-[927px]'>
                    
            <div className='flex items-center justify-end mb-4 px-4'>
        
                <div className='me-3 ' style={{minWidth:'300px'}}>
                    <input type='text' className='form-control form-st-4' placeholder='ค้นหาจากรหัส/ชื่อ' onKeyUp={e => setFilterText(e.target.value)} />
                </div>
                <Button 
                className={'rounded-[8px] h-[37px] w-[150px] text-[#FFF] bg-[#8955C1]' } 
                variant={'contained'}
                onClick={() => setOpen(true) }
                
                >
                    เพิ่มประเภทประกัน
                </Button>
            </div>
            <div className="card border-0 bg-white rounded-[10px] overflow-hidden " style={{
                boxShadow: '0px 2px 10px 0px #4C4E6438'
            }}>
                <div className="card-body p-0">
                    
                    <DataGrid
                        getRowId={(row) => row.id}
                        checkboxSelection 
                        rows={insType}
                        autoHeight={true}
                        columns={columnsd}
                        /* initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }} */
                        pagination
                        paginationMode="server"
                        paginationModel={{
                            page: pageModel.page - 1,
                            pageSize: pageModel.perPage
                        }}
                        onPaginationModelChange={(newPagination) => {
                        setPageModel(prev => ({
                            ...prev,
                            page: newPagination.page+1, 
                            pageSize: newPagination.pageSize
                        }))
                        loadAll(newPagination.page+1, newPagination.pageSize,filterText || "");
                        }}
                        rowsPerPage={pageModel.page}
                        rowCount={pageModel.total}

                        disableRowSelectionOnClick 
                        enableCellSelect={true}
                        pageSizeOptions={[5,10,15]}
                        slotProps={{ pagination: { labelRowsPerPage: 'จำนวนรายการต่อหน้า' } }}
                        disableMultipleRowSelection={false}
                        sx={{
                            boxShadow: 0,
                            border: 0,
                            fontWeight:900,
                            color:'#000000',
                            fontFamily: 'Sarabun',
                            fontSize:'14px',
                            borderRadius:0,
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiTablePagination-selectLabel': {
                                fontFamily: 'Sarabun',
                                marginBottom:'3px'
                            },
                            '& .MuiTablePagination-displayedRows': {
                                fontFamily: 'Sarabun',
                                marginBottom:'3px'
                            },
                            '& .MuiDataGrid-filler': {
                                background:'#F5F5F7'
                            },
                            '& .MuiDataGrid-columnHeader':{
                                background:'#F5F5F7'
                            },
                            '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root':{
                                color:'#4C4E64AD'
                            }
                        
                        }}
                    />
                
                </div>

            </div>

            <ModalCreate open={open} setOpen={setOpen} />

            <ModalEdit open={openEdit} setOpen={setOpenEdit} formedit={formedit} setInsType={setInsType} />
        </div>
    )
}


const ModalCreate = ({open,setOpen}) => {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();

    const [optionsPlace, setOptionsPlace] = useState([]);

    const placeReducer = useAppSelector(
        (state) => state.placeReducer
    );

    const loadAllPlace = () => {
        dispatch(getAllPlace());
    };

    useEffect(() => {
        loadAllPlace();
    },[]);

    useEffect(() => {
        setIsLoading( 
            placeReducer.isFetching
        );

        if(placeReducer.data){
            const options = mapPlaceToOptions(placeReducer.data.data);
            setOptionsPlace([...options]);
        }
    },[ placeReducer ]);

    const schema = yup.object().shape({
        place_id: yup.number().required(),
        code: yup.string().required(),
        name: yup.string().required(),
        is_status: yup.boolean().required(),
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [formdata, setFormdata] = useState({"place_id":"","name":"","code": "","is_status":""})

    return (
    <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
            <div className='flex items-center mb-7'>
                <div className='mr-auto text-lg'> เพิ่มประเภทประกัน</div>
                <IconButton aria-label="" className='p-0' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Formik
                initialValues={formdata}
                validationSchema={schema}
                enableReinitialize
                onSubmit={async (values) => {
                    console.log('Create-Value',values);
                    dispatch(createInsuranceType(values, { modalObj ,setModalObj }))
                    setOpen(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>

                    <Row className=''>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รหัสประเภทประกัน </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='code'
                                placeholder="รหัสประเภทประกัน" 
                                onChange={handleChange}
                                value={values.code}
                                className='form-st-1 border-[1px] border-[#4C4E6438]'
                                // disabled={true}
                                />
                            </Form.Group>
                        </Col>
            
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ชื่อประเภทประกัน </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='name'
                                placeholder="ชื่อประเภทประกัน" 
                                onChange={handleChange}
                                value={values.name}
                                className='form-st-1'
                                isInvalid={!!errors.name}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานที่ซ่อม</Form.Label>
                                <SelectS1
                                    as="select"
                                    name="place_id"
                                    className={!!errors.place_id ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    onChange={(e) => {
                                        setFieldValue("place_id", e.value);
                                    }}
                                    options={optionsPlace}
                                    placeholder="กรุณาเลือกสถานที่ซ่อม"
                                    isInvalid={!!errors.place_id}
                                    // value={values.place_id}
                                />
                            </Form.Group>
                        </Col>
            
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานะ</Form.Label>
                                <div className='inlineForm p-2 '
                                    style={{
                                        border:!!errors.is_status ? '1px solid #dc3545' : 0,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Form.Check
                                        inline
                                        label="ปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-1`}
                                        checked={ values.is_status !== '' && !values.is_status }
                                        onChange={() => {
                                            setFieldValue('is_status', false)
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label="เปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-2`}
                                        checked={ values.is_status !== '' && values.is_status }
                                        onChange={() => {
                                            setFieldValue('is_status', true)
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className='flex justify-end mt-4'>
                        
                        <ButtonM2
                        onClick={() => setOpen(false)}
                        >ยกเลิก</ButtonM2>

                        <ButtonM1 
                        type="submit"  
                        >
                            บันทึก
                        </ButtonM1>
                    </div>
                    
                    </form>
                )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
}


const ModalEdit = ({open,setOpen,formedit,setInsType}) => {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();

    const [optionsPlace, setOptionsPlace] = useState([]);

    const placeReducer = useAppSelector(
        (state) => state.placeReducer
    );

    const loadAllPlace = () => {
        dispatch(getAllPlace());
    };

    useEffect(() => {
        loadAllPlace();
    },[]);

    useEffect(() => {
        setIsLoading( 
            placeReducer.isFetching
        );

        if(placeReducer.data){
            const options = mapPlaceToOptions(placeReducer.data.data);
            setOptionsPlace([...options]);
        }
    },[ placeReducer ]);


    const schema = yup.object().shape({
        name: yup.string().required(),
        is_status: yup.boolean().required(),
    });


    const handleClose = () => setOpen(false);


    const [formdata, setFormdata] = useState({})
    
    useEffect(() => {
        setFormdata(formedit);
    }, [formedit]);

    return (
        <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <div className='flex items-center mb-7'>
                <div className='mr-auto text-lg'> จัดการประเภทประกัน</div>
                <IconButton aria-label="" className='p-0' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Formik
                initialValues={formdata}
                validationSchema={schema}
                enableReinitialize
                onSubmit={async (values) => {
                    console.log('Update Value',values);
                    const {id,place,...payloadData} = values;
                    dispatch(updateInsuranceType(values.id, payloadData, { modalObj,setModalObj }));
                    setOpen(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>

                    <Row className=''>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รหัสประเภทประกัน </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='code'
                                placeholder="รหัสประเภทประกัน" 
                                onChange={handleChange}
                                value={values.code}
                                className='form-st-1 border-[1px] border-[#4C4E6438]'
                                disabled={true}
                                />
                            </Form.Group>
                        </Col>
            
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ชื่อประเภทประกัน </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='name'
                                placeholder="ชื่อประเภทประกัน" 
                                onChange={handleChange}
                                value={values.name}
                                className='form-st-1'
                                isInvalid={!!errors.name}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานที่ซ่อม</Form.Label>
                                <SelectS1
                                    as="select"
                                    name="id"
                                    className={!!errors.id ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                    options={optionsPlace}
                                    onChange={(e) => {
                                        setFieldValue('place_id', e.value)
                                    }}
                                    placeholder="กรุณาเลือกสถานที่ซ่อม"
                                    isInvalid={!!errors.place_id}
                                    value={
                                        values.place_id
                                        ? optionsPlace.find(option => option.value === values.place_id) 
                                        :(formdata?optionsPlace.find(option => option.value === values.place_id)
                                        : null)
                                    }
                                />
                            </Form.Group>
                        </Col>
            
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานะ</Form.Label>
                                <div className='inlineForm p-2 '
                                    style={{
                                        border:!!errors.is_status ? '1px solid #dc3545' : 0,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Form.Check
                                        inline
                                        label="ปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-1`}
                                        checked={!values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status', false )
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label="เปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-2`}
                                        checked={values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status', true )
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            
                        </Col>
                    </Row>

                    <div className='flex  mt-4'>
                        <div className="mr-auto">
                            <ButtonM3

                            onClick={() => {
                                setInsType(prev => prev.filter(item => item.id !== values.id))
                                setOpen(false)
                            }}
                            >ลบ</ButtonM3>
                        </div>
                        

                        <ButtonM2
                        onClick={() => setOpen(false)}
                        >ยกเลิก</ButtonM2>

                        <ButtonM1 
                        type="submit"  
                        >
                            บันทึก
                        </ButtonM1>
                    </div>
                    
                    </form>
                )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 438,
    bgcolor: 'background.paper',
    boxShadow: '0px 0px 20px 0px #4C4E6433',
    p: 3,
    borderRadius:'10px',
    zIndex:999999
    
};