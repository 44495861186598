

import React, { useState,useEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Image from 'react-bootstrap/Image';
import DeleteIcon from 'assets/icon/solar_trash-bin-trash-outline.svg'
import EditIcon from 'assets/icon/cil_pencil.svg'
import ImgIcon from 'assets/icon/image.png'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import zIndex from '@mui/material/styles/zIndex';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SelectS1 } from 'fragments/Select';
import Form from 'react-bootstrap/Form';

import { ButtonM1,ButtonM2,ButtonM3 } from 'fragments/ButtonModal';


import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { useGlobalLoaderContext } from 'contexts/GlobalLoaderContext';
import { getAllCarBrand } from '../../redux/handlers/master/carBrand.handler';
import { base64ToBlob } from 'utils/HelperFunction';
import { createCarBrand } from '../../redux/handlers/master/createCarBrand.handler';
import { domainUploadUrl } from 'constants/server/api';
import { updateCarBrand } from '../../redux/handlers/master/updateCarBrand.handler';
import { deleteCarBrand } from '../../redux/handlers/master/deleteCarBrand.handler';
import { debounce } from '@mui/material';
import { useMemo } from 'react';

export function CarBrand() {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();
    const [carBrand, setCarBrand] = useState([])

    const carBrandReducer = useAppSelector(
        (state) => state.carBrandReducer
    );

    const [pageModel,setPageModel] = useState({
            page: 1,
            perPage: 10,
            total: 0
        });
    
    const loadAll = (page,perPage,searchText) => {
        const queryParams = {
            page: page,
            perPage: perPage,
            searchText: searchText,
        } 
        dispatch(getAllCarBrand({ queryParams, modalObj, setModalObj }));
    }
    

    useEffect(() => {
        loadAll(1,pageModel.perPage,filterText || "");
    },[]);

    useEffect(() => {
            setIsLoading(
                carBrandReducer.isFetching
            )
    
            if(carBrandReducer.data && carBrandReducer.data.data){
                const meta = carBrandReducer.data.data.meta;
                setPageModel({
                    page: meta.currentPage,
                    perPage: meta.perPage,
                    total: meta.total
                });
                setCarBrand(carBrandReducer.data.data.data); 
            }
    },[
        carBrandReducer
    ]);

    const createCarBrandReducer = useAppSelector(
            (state) => state.createCarBrandReducer
    );

    useEffect(() => {
        setIsLoading(createCarBrandReducer.isFetching);
        if(createCarBrandReducer.data && !createCarBrandReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    }, [createCarBrandReducer]);

    const updateCarBrandReducer = useAppSelector(
        (state) => state.updateCarBrandReducer
    );

    useEffect(() => {
        setIsLoading(updateCarBrandReducer.isFetching);
        if(updateCarBrandReducer.data && !updateCarBrandReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    }, [updateCarBrandReducer])

    const deleteCarBrandReducer = useAppSelector(
        (state) => state.deleteCarBrandReducer
    )

    useEffect(() => {
        setIsLoading(deleteCarBrandReducer.isFetching);
        if(deleteCarBrandReducer.data && !deleteCarBrandReducer.isError){
            loadAll(pageModel.page,pageModel.perPage,filterText || "");
        }
    }, [deleteCarBrandReducer]);

    const columnsd = [
        { 
            field: 'code', 
            headerClassName: 'bg-gray ps-4',
            headerName: 'รหัสยี่ห้อ', width: 100,
            sortable: false,
            cellClassName:'text-[#636578] font-normal',
        },
        {
            field: 'name',
            headerClassName: 'bg-gray',
            headerName: 'ชื่อยี่ห้อ',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
            <div className='flex items-center'>
                <img src={`${domainUploadUrl}/${row.image_path}`} className='w-[38px]' /> 
                <span className='text-[#636578] font-normal ml-3 '>{row.name}</span>
            </div>
            )
        },
        {
            field: 'is_status',
            headerClassName: 'bg-gray',
            cellClassName:'text-center',
            headerAlign: 'center',
            headerName: 'สถานะ',
            width: 150,
            sortable: false,
            renderCell: ({ row }) => {
            return <Button 
            className={` ${row.is_status ? 'bg-[#EEFBE5] text-[#72E128]' : 'bg-[#FFE4E6] text-[#F95057]' } p-0 w-[127px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}

            onClick={() => {
                dispatch(updateCarBrand(row.id,{...row,is_status:!row.is_status}, { modalObj,setModalObj }));
            }}
            
            >{`${row.is_status ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }`}</Button>
        } 
        },
        {
            field: 'id',
            headerClassName: 'bg-gray',
            headerAlign: 'center',
            headerName: 'จัดการ',
            width: 150,
            cellClassName:'text-center',
            sortable: false,
            
            renderCell: ({ row }) => (
                <>
                <Button 
                className={`bg-[#E9E9E9] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    setFormedit(row) 
                    setOpenEdit(true)
                }}
                >
                    <img src={EditIcon} className=" w-[16px] h-[16px]"  />
                </Button>
                <Button 
                className={`bg-[#F95057] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] mx-1`}
                onClick={() => {
                    setCarBrand(prev => prev.filter(item => item.id !== row.id));
                    dispatch(deleteCarBrand(row.id, row, { modalObj,setModalObj }));
                }}
                ><img src={DeleteIcon} className=" w-[19px] h-[19px]"  /></Button>
                </>
            )
        }
    ];

    const [filterText, setFilterText] = useState(null);    
    const debouncedSearch = useMemo(
        () =>
            debounce((searchText) => {
            loadAll(1, pageModel.perPage, searchText || "")
            }, 300),
        []
    );
    useEffect(() => {
        if(filterText !== null) {
            debouncedSearch(filterText || "");
        }
    }, [filterText]);

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [formedit, setFormedit] = React.useState({});

    
    return (
        <div className='max-w-[700px]'>
                    
            <div className='flex items-center justify-end mb-4 px-4'>
        
                <div className='me-3 ' style={{minWidth:'300px'}}>
                    <input type='text' className='form-control form-st-4' placeholder='ค้นหาจากรหัส/ชื่อ' onKeyUp={e => setFilterText(e.target.value)} />
                </div>
                <Button 
                className={'rounded-[8px] h-[37px] w-[130px] text-[#FFF] bg-[#8955C1]' } 
                variant={'contained'}
                onClick={() => setOpen(true) }
                
                >
                    เพิ่มยี่ห้อ
                </Button>
            </div>
            <div className="card border-0 bg-white rounded-[10px] overflow-hidden " style={{
                boxShadow: '0px 2px 10px 0px #4C4E6438'
            }}>
                <div className="card-body p-0">
                    
                    <DataGrid
                        getRowId={(row) => row.id}
                        checkboxSelection 
                        rows={carBrand}
                        autoHeight={true}
                        columns={columnsd}
                        pagination
                        paginationMode="server"
                        paginationModel={{
                            page: pageModel.page - 1,
                            pageSize: pageModel.perPage
                        }}
                        onPaginationModelChange={(newPagination) => {
                        setPageModel(prev => ({
                            ...prev,
                            page: newPagination.page+1, 
                            pageSize: newPagination.pageSize
                        }))
                        loadAll(newPagination.page+1, newPagination.pageSize,filterText || "");
                        }}
                        rowsPerPage={pageModel.page}
                        rowCount={pageModel.total}

                        disableRowSelectionOnClick 
                        enableCellSelect={true}
                        pageSizeOptions={[5,10,15]}
                        slotProps={{ pagination: { labelRowsPerPage: 'จำนวนรายการต่อหน้า' } }}
                        disableMultipleRowSelection={false}
                        sx={{
                            boxShadow: 0,
                            border: 0,
                            fontWeight:900,
                            color:'#000000',
                            fontFamily: 'Sarabun',
                            fontSize:'14px',
                            borderRadius:0,
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiTablePagination-selectLabel': {
                                fontFamily: 'Sarabun',
                                marginBottom:'3px'
                            },
                            '& .MuiTablePagination-displayedRows': {
                                fontFamily: 'Sarabun',
                                marginBottom:'3px'
                            },
                            '& .MuiDataGrid-filler': {
                                background:'#F5F5F7'
                            },
                            '& .MuiDataGrid-columnHeader':{
                                background:'#F5F5F7'
                            },
                            '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root':{
                                color:'#4C4E64AD'
                            }
                        
                        }}
                    />
                
                </div>

            </div>

            <ModalCreate open={open} setOpen={setOpen} />

            <ModalEdit open={openEdit} setOpen={setOpenEdit} formedit={formedit} setCarBrand={setCarBrand} />

        </div>
    )
}


const ModalCreate = ({open,setOpen}) => {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();

    const schema = yup.object().shape({
        code: yup.string().required(),
        name: yup.string().required(),
        is_status: yup.boolean().required(),
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [formdata, setFormdata] = useState({"name":"","code": "","is_status":"","image_file":""})

    const inputFileRef = useRef(null);
    const [image, setImage] = useState(ImgIcon);

 
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className='flex items-center mb-7'>
                <div className='mr-auto text-lg'> เพิ่มยี่ห้อ</div>
                <IconButton aria-label="" className='p-0' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Formik
                initialValues={formdata}
                validationSchema={schema}
                enableReinitialize
                onSubmit={async (values) => {
                    console.log('VALUES',values);
                    const formdataPayload = new FormData();
                    for (const key in values) {
                    if (Object.hasOwnProperty.call(values, key)) {
                        let value = values[key];
                        if (key === "image_file" && typeof value === "string" && value) 
                        {
                            const blob = base64ToBlob(value);
                            formdataPayload.append(key, blob, "img_file.jpg");
                        } else if (value !== undefined && value !== "") {
                            formdataPayload.append(key, value);
                        }
                    }}
                    console.log('FORMDATA-PAYLOAD',Object.fromEntries(formdataPayload.entries()));
                    dispatch(createCarBrand(formdataPayload,{ modalObj, setModalObj }));
                    setOpen(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>

                    <Row className=''>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รหัสยี่ห้อ </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='code'
                                placeholder="รหัสยี่ห้อ" 
                                onChange={handleChange}
                                value={values.code}
                                className='form-st-1 border-[1px] border-[#4C4E6438]'
                                // disabled={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ชื่อยี่ห้อรถ </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='name'
                                placeholder="ชื่อยี่ห้อรถ" 
                                onChange={handleChange}
                                value={values.name}
                                className='form-st-1'
                                isInvalid={!!errors.name}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รูปภาพ </Form.Label>
                                <div className='flex items-center '>
                                    <div className='w-[124px] relative flex-[0_0_124px] h-[124px] bg-[#F4F6F9] flex items-center justify-center rounded-[12px] border-[1px] border-[#D6D6D6] p-2'>
                                        
                                        {
                                            inputFileRef.current && inputFileRef.current.value ? 
                                            <>
                                            <Button 
                                            className={`bg-[#F95057] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] absolute right-[5px] bottom-[5px]`}
                                            onClick={() => {
                                                setImage(ImgIcon)
                                                inputFileRef.current.value= ""
                                            }}
                                            
                                            ><img src={DeleteIcon} className=" w-[19px] h-[19px]"  /></Button> 
                                            <img src={image} className='object-cover w-[110px] h-[110px] rounded' />
                                            </>
                                            : <img src={image} className='object-cover w-[40px] h-[40px] rounded' />
                                        }
                                        
                                        
                                    </div>
                                    <div className='grow ml-5'>
                                        <input
                                            ref={inputFileRef}
                                            accept="image/*"
                                            hidden
                                            id="image-upload"
                                            type="file"
                                            onChange={(event) => {
                                                const newImage = event.target?.files?.[0];
                                                if (newImage) {
                                                    setImage(URL.createObjectURL(newImage));
                                                    setFieldValue("image_file",newImage)
                                                }
                                            }}
                                            name="image_file"
                                        />
                                        <Button className='bg-[#FFA319] text-white rounded-[7px] mb-2'
                                        onClick={() => inputFileRef.current.click()}
                                        >อัพโหลดรูปภาพ</Button>
                                        <div className='text-sm text-[background: #4C4E64AD]'>อัพโหลดไฟล์ JPG, GIF or PNG. 
                                        ขนาดไม่เกิน 800K</div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานะ</Form.Label>
                                <div className='inlineForm p-2 '
                                    style={{
                                        border:!!errors.is_status ? '1px solid #dc3545' : 0,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Form.Check
                                        inline
                                        label="ปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-1`}
                                        checked={values.is_status !== "" && !values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',false)
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label="เปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-2`}
                                        checked={values.is_status !== "" && values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',true)
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            
                        </Col>
                    </Row>

                    <div className='flex justify-end mt-4'>
                        
                        <ButtonM2
                        onClick={() => setOpen(false)}
                        >ยกเลิก</ButtonM2>

                        <ButtonM1 
                        type="submit"  
                        >
                            บันทึก
                        </ButtonM1>
                    </div>
                    
                    </form>
                )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
}


const ModalEdit = ({open,setOpen,formedit,setCarBrand}) => {
    const dispatch = useAppDispatch();
    const { modalObj, setModalObj } = useGlobalModalContext();
    const { isloading, setIsLoading } = useGlobalLoaderContext();

    const schema = yup.object().shape({
        code: yup.string().required(),
        name: yup.string().required(),
        is_status: yup.boolean().required(),
    });


    const handleClose = () => setOpen(false);


    const [formdata, setFormdata] = useState(/* {"code": "B001"} */)

    
    useEffect(() => {
        setFormdata(formedit)
        setImage(`${domainUploadUrl}/${formedit.image_path}`)
    }, [formedit]);

    const inputFileRef = useRef(null);
    const [image, setImage] = useState(ImgIcon);

    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
            <div className='flex items-center mb-7'>
                <div className='mr-auto text-lg'> จัดการรุ่น</div>
                <IconButton aria-label="" className='p-0' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Formik
                initialValues={formdata}
                validationSchema={schema}
                enableReinitialize
                onSubmit={async (values) => {
                    const {id,...payloadData} = values;
                    const formdataPayload = new FormData();
                    for (const key in payloadData) {
                    if (Object.hasOwnProperty.call(payloadData, key)) {
                        let value = payloadData[key];
                        if (key === "image_file" && typeof value === "string" && value) 
                        {
                            const blob = base64ToBlob(value);
                            formdataPayload.append(key, blob, "img_file.jpg");
                        } else if (value !== undefined && value !== "") {
                            formdataPayload.append(key, value);
                        }
                    }}
                    dispatch(updateCarBrand(values.id,formdataPayload,{ modalObj, setModalObj }));
                    setOpen(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>

                    <Row className=''>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รหัสยี่ห้อ </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='code'
                                placeholder="รหัสยี่ห้อ" 
                                onChange={handleChange}
                                value={values.code}
                                className='form-st-1 border-[1px] border-[#4C4E6438]'
                                disabled={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>ชื่อยี่ห้อรถ </Form.Label>
                                <Form.Control 
                                type="text" 
                                name='name'
                                placeholder="ชื่อยี่ห้อรถ" 
                                onChange={handleChange}
                                value={values.name}
                                className='form-st-1'
                                isInvalid={!!errors.name}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="">
                                <Form.Label>รูปภาพ </Form.Label>
                                <div className='flex items-center '>
                                    <div className='w-[124px] relative flex-[0_0_124px] h-[124px] bg-[#F4F6F9] flex items-center justify-center rounded-[12px] border-[1px] border-[#D6D6D6] p-2'>
                                        
                                        {
                                            image ? 
                                            <>
                                            <Button 
                                            className={`bg-[#F95057] p-0 w-[27px] h-[27px] rounded-full text-sm min-w-[27px] absolute right-[5px] bottom-[5px]`}
                                            onClick={() => {
                                                setImage("")
                                                inputFileRef.current.value= ""
                                            }}
                                            
                                            ><img src={DeleteIcon} className=" w-[19px] h-[19px]"  /></Button> 
                                            <img src={image} className='object-cover w-[110px] h-[110px] rounded' />
                                            </>
                                            : <img src={'/image.png'} className='object-cover w-[40px] h-[40px] rounded' />
                                        }
                                        
                                        
                                    </div>
                                    <div className='grow ml-5'>
                                        <input
                                            ref={inputFileRef}
                                            value=""
                                            accept="image/*"
                                            hidden
                                            id="image-upload"
                                            type="file"
                                            onChange={(event) => {
                                                const newImage = event.target?.files?.[0];
                                                
                                                if (newImage) {
                                                    setImage(URL.createObjectURL(newImage));
                                                    setFieldValue("image_file",newImage)
                                                }
                                            }}
                                            name="image_file"
                                        />
                                        <Button className='bg-[#FFA319] text-white rounded-[7px] mb-2'
                                        onClick={() => inputFileRef.current.click()}
                                        >อัพโหลดรูปภาพ</Button>
                                        <div className='text-sm text-[background: #4C4E64AD]'>อัพโหลดไฟล์ JPG, GIF or PNG. 
                                        ขนาดไม่เกิน 800K</div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                <Form.Label>สถานะ</Form.Label>
                                <div className='inlineForm p-2 '
                                    style={{
                                        border:!!errors.is_status ? '1px solid #dc3545' : 0,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Form.Check
                                        inline
                                        label="ปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-1`}
                                        checked={!values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',false)
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label="เปิดใช้งาน"
                                        name="is_status"
                                        type="radio"
                                        id={`inline-radio-2`}
                                        checked={values.is_status}
                                        onChange={() => {
                                            setFieldValue('is_status',true)
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            
                        </Col>
                    </Row>

                    <div className='flex  mt-4'>
                        <div className="mr-auto">
                            <ButtonM3

                            onClick={() => {
                                setCarBrand(prev => prev.filter(item => item.id !== values.id))
                                setOpen(false)
                            }}
                            >ลบ</ButtonM3>
                        </div>
                        

                        <ButtonM2
                        onClick={() => setOpen(false)}
                        >ยกเลิก</ButtonM2>

                        <ButtonM1 
                        type="submit"  
                        >
                            บันทึก
                        </ButtonM1>
                    </div>
                    
                    </form>
                )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 438,
    bgcolor: 'background.paper',
    boxShadow: '0px 0px 20px 0px #4C4E6433',
    p: 3,
    borderRadius:'10px',
    zIndex:999999
    
};