const domain = "https://deejing-dev-api.seamlesssolution.co";

export { domain };

export const apiUrl = "/api/v1";

export const domainUploadUrl = "https://deejing-storage.seamlesssolution.co/cdn-cgi/image/quality=100";

export const YES = "YES";
export const NO = "NO";
export const OK = "OK";
export const TOKEN = "TOKEN";
export const VER = "1";

export const endPoint = {
  TEST_TABLE_URL: `${apiUrl}/test-table`,
  CAR_BRAND: `${apiUrl}/car-brand`,
  CAR_CODE: `${apiUrl}/car-code`,
  CAR_MODEL: `${apiUrl}/car-model`,
  CAR_SUB_MODEL: `${apiUrl}/car-sub-model`,
  LICENSE_PLATE_PROVINCE: `${apiUrl}/license-plate-province`,
  INSURANCE_TYPE: `${apiUrl}/insurance-type`,
  PLACE: `${apiUrl}/place`,
  INSURANCE: `${apiUrl}/insurance`,
  COLOR: `${apiUrl}/color`,
};
