import * as getAllInsuranceTypeAction from "../../slices/master/insuranceType.slice";
import { http } from "../../../utils/http";
import { apiUrl, endPoint } from "../../../constants/server/api";

export const getAllInsuranceType = ({ queryParams, modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(getAllInsuranceTypeAction.fetchData());

    try {
      let url = endPoint.INSURANCE_TYPE;
      
      if(queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        url = `${url}?${queryString}`
      }

      const config = {
        method: "get",
        url: url
        // url: `${apiUrl}/insurance-type/list`
      };

      const result = await http(config);
      dispatch(getAllInsuranceTypeAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllInsuranceTypeAction.fetchDataFailure());
    }
  };
};
