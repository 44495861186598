import * as getCarBrandListActions from "../../slices/master/getCarBrandList.slice";
import { http } from "../../../utils/http";
import { endPoint } from "constants/server/api";

export const getCarBrandList = () => {
  return async (dispatch) => {
    dispatch(getCarBrandListActions.fetchData());

    try {
      const config = {
        method: "get",
        url:  `${endPoint.CAR_BRAND}/list`
      };

      const result = await http(config);
      dispatch(getCarBrandListActions.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getCarBrandListActions.fetchDataFailure());
    }
  };
};
