import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'tailwind.css';

export function Login() {
  const navigate = useNavigate();
  // const { isloading, setIsLoading } = useGlobalLoaderContext();


  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const [inputtype, setInputtype] = useState("password");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    remember: false,
  });


  const handleLogin = (values) => {
    
  };



  return (
    <div className="loginpage p-0 bg-white"
    >
      <Row className="g-0 md:w-full" >
        <Col lg={7} xl={8} 
        className="d-none d-lg-flex justify-content-center flex-column align-items-center py-5 h-100vh"
        style={{
          backgroundImage:'url(images/login_bg.png),linear-gradient(180deg, #FBFBFB 19.27%, #FCFCFC 39.06%, #FFFFFF 54.17%)',
          backgroundSize:'60% auto,cover',
          backgroundPosition:'left bottom,center',
          backgroundRepeat:'no-repeat,no-repeat'
        }}
        >
          <Image src="/images/logo.png" width={250} className="mb-4 mr-auto mb-auto" />
          <Image src="/images/login_img.png"  className="mb-4 mx-auto w-[65vh] max-w-[570px] mb-auto" />
          {/* <Image
            src="/images/cover_login.jpg"
            className="w-full"
            style={{ height: "100vh", objectFit: "cover" }}
          /> */}
        </Col>
        <Col lg={5} xl={4} className="d-flex justify-content-center align-items-center h-100vh">
          <Card className="box-login border-0 ">
            <Card.Body className="">
              <div className="text-center">
                <Image src="/images/logo.png" width={200} className="mb-4 mx-auto" />
              </div>
              <div className="text-center text-[24px]  mb-7 font-semibold mb-3 tc-1 text-[#8955C1]">
                ยินดีต้อนรับ
              </div>

              <Formik
                initialValues={formData}
                validationSchema={schema}
                onSubmit={handleLogin}
              >
                {({
                  handleSubmit,
                  handleChange,
                  touched,
                  isValid,
                  errors,
                  values,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="อีเมลหรือเบอร์โทรศัพท์"
                      onChange={handleChange}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      className="form-st-1 mb-3"
                    />
                    <div className="position-relative">
                      <Form.Control
                        type={inputtype}
                        name="password"
                        placeholder="รหัสผ่าน"
                        onChange={handleChange}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                        className="form-st-1 mb-3"
                      />
                      <Button
                        type="button"
                        className="btn btn-change-type-password"
                        onClick={() =>
                          inputtype == "password"
                            ? setInputtype("text")
                            : setInputtype("password")
                        }
                      ></Button>
                    </div>
                    <div className="d-flex justify-content-between my-4">
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id="remember"
                        label="จดจำการใช้งานระบบ"
                        defaultChecked={true}
                      />

                      <Link
                        to="/reset/password"
                        variant="link"
                        className="btn alink-st-1 tc-1 text-[#FFA319]"
                      >
                        ลืมรหัสผ่าน ?
                      </Link>
                    </div>

                    <Button
                      type="submit"
                      className="btn-login text-lg font-normal w-100 mb-4 bg-[#8955C1] border-0"
                    >
                      เข้าสู่ระบบ
                    </Button>
                      
                    
                    <div className="text-center pt-2 flex items-center text-sm justify-center flex-wrap">
                      <div className="mr-1">หากพบปัญหาการเข้าสู่ระบบ</div>
                      <div className="ml-1">
                        <a href="" className="text-[#8955C1] underline"> กรุณาติดต่อผู้ดูแลระบบ</a>
                      </div>
                    </div>


                     
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
