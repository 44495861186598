import * as createLicensePlateProvinceActions from "../../slices/master/createLicensePlateProvince.slice";
import { http } from "../../../utils/http";
import { endPoint } from "constants/server/api";

export const createLicensePlateProvince = (payload,{ modalObj, setModalObj }) => {
  return async (dispatch) => {
    dispatch(createLicensePlateProvinceActions.fetchData());

    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        method: "post",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        url: endPoint.LICENSE_PLATE_PROVINCE,
        data: payload
      };

      const result = await http(config);
      dispatch(createLicensePlateProvinceActions.fetchDataSuccess(result.data));
      
    } catch (error) {
      if (http.isAxiosError(error) && error.response) {
        const statusCode = error.response.status;
        setModalObj({
          ...modalObj,
          visible: true,
          subTitle: `รหัส ${statusCode}`,
          navigateDirection: undefined,
          title: `${error.response.data.message}`
        });
      } else {
        setModalObj({
          ...modalObj,
          visible: true,
          subTitle: `รหัส 500`,
          navigateDirection: undefined,
          title: 'เกิดข้อผิดพลาด\nหรือเครือข่ายขัดข้อง'
        });
      }
      dispatch(createLicensePlateProvinceActions.fetchDataFailure());
    }
  };
};

export const clearCreateLicensePlateProvinceActions = () => {
  return async (dispatch) => {
    dispatch(createLicensePlateProvinceActions.resetState());
  };
};

